import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import 'styles/components/modal-content.scss';
import ScrollContainer from 'components/ScrollContainer';
import {TweenMax, TimelineMax} from 'gsap';
import {tabbable} from 'utils/accessibility'
import {hideMainPage, showMainPage} from 'utils'

import {
  showElemWithAria, hideElementWithAria
} from 'utils/animation';

const stickyPositions = {
  NO_STICKY: 'modal-position-no-sticky',
  LEFT: 'modal-position-left',
  RIGHT: 'modal-position-right',
};

const modalBackgrounds = {
  BLACK: 'modal-background-black',
  WHITE: 'modal-background-white',
};

const modalWindowBackgrounds = {
  WHITE: 'modal-window-white',
  TRANSPARENT: 'modal-window-transparent',
};

const modalWindowPositions = {
  LEFT: 'modal-position-left',
  RIGHT: 'modal-position-right',
};

const TAB_KEY = 9;

const ModalContent = class extends React.Component {
  constructor(props) {
    super(props);
    this.modalContainer = document.createElement('div');
    document.body.appendChild(this.modalContainer);
  }

  componentDidMount() {
    this.modalElems = [
      this.modalBackgroundEl,
      this.modalCloseBtnEl,
      this.modalBoxEl,
    ];
    this.setModalTimelines();
    this.setInitialModalState();

    hideMainPage()

    window.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    this.modalTimeline.kill();
    TweenMax.killTweensOf(this.modalElems);

    showMainPage()

    const mainNavigation = document.getElementById('mainNavigation')
    mainNavigation.focus()

    window.removeEventListener('keydown', this.onKeyDown)
    document.body.removeChild(this.modalContainer);
  }

  setModalTimelines = () => {
    this.modalTimeline = new TimelineMax({
      paused: true, onReverseComplete: this.onReverseComplete,
    });
    this.modalTimeline.fromTo(
        this.modalBackgroundEl, 0.3, {...hideElementWithAria}, {autoAlpha: 0.9, attr: {'aria-hidden': false} }
    );
    this.modalTimeline.fromTo(
        this.modalBoxEl, 0.3, {...hideElementWithAria}, {...showElemWithAria}, "-=0.3"
    );
    this.modalTimeline.fromTo(
        this.modalCloseBtnEl, 0.3, {...hideElementWithAria}, {...showElemWithAria}, "-=0.3"
    );

    this.modalTimeline.play();

    this.modalTimeline.eventCallback('onStart', () => {
      requestAnimationFrame( () => {
        this.content.focus();
      })
    })
  }

  setInitialModalState = () => {
    this.modalElems.forEach((elem) => {
      TweenMax.set(elem, {...hideElementWithAria});
    });
  }

  onOpenClick = () => {
    this.modalTimeline.play();
  }

  onCloseClick = () => {
    this.modalTimeline.reverse();
  }

  onReverseComplete = () => {
    this.props.onClose();
  }

  onKeyDown = e => {
    if (e.keyCode === TAB_KEY) {
      if (document.activeElement === this.closeBtn) {
        e.preventDefault()
        this.closeBtn.focus()
      }
    }
  }

  render() {
    const {
      stickyPosition,
      modalBackground,
      modalWindowBackground,
      modalWindowPosition
    } = this.props;

    const windowPosition = !modalWindowPosition
      ? stickyPosition === stickyPositions.NO_STICKY
        ? modalWindowPositions.LEFT : stickyPosition
        : modalWindowPosition;

    const modalClass =
      `${stickyPosition} ${windowPosition} ${modalBackground} ${modalWindowBackground}`;

    return ReactDOM.createPortal(
        <div className={`selma-modal ${modalClass}`}>
          <div className="selma-modal__background"
            onClick={this.onCloseClick}
            ref={(ref) => (this.modalBackgroundEl = ref)}
          />
          <div ref={(ref) => (this.modalBoxEl = ref)}>
            <ScrollContainer className="selma-modal__modal-window">
              <div {...tabbable} ref={ref => this.content = ref}>
                {this.props.children}
              </div>
            </ScrollContainer>
          </div>
          <div className={`selma-modal__close-btn`}
            ref={(ref) => (this.modalCloseBtnEl = ref)}
          >
            <button className="close-btn"
              ref={ref => this.closeBtn = ref}
              name="close modal"
              type="button"
              aria-label="Close Modal"
              onClick={this.onCloseClick}
            >
              <div className="x-stick"/>
            </button>
          </div>
        </div>,
        this.modalContainer
    );
  }
};

ModalContent.propTypes = {
  children: PropTypes.element.isRequired,
  stickyPosition: PropTypes.oneOf([
    stickyPositions.NO_STICKY, stickyPositions.LEFT, stickyPositions.RIGHT,
  ]),
  triggerHeight: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  modalBackground: PropTypes.oneOf([
    modalBackgrounds.BLACK, modalBackgrounds.WHITE,
  ]),
  modalWindowBackground: PropTypes.oneOf([
    modalWindowBackgrounds.WHITE, modalWindowBackgrounds.TRANSPARENT,
  ]),
  modalWindowPosition: PropTypes.oneOf([
    modalWindowPositions.LEFT, modalWindowPositions.RIGHT,
  ]),
};

ModalContent.defaultProps = {
  stickyPosition: stickyPositions.NO_STICKY,
  triggerHeight: 0,
  modalBackground: modalBackgrounds.BLACK,
  modalWindowBackground: modalWindowBackgrounds.WHITE,
};

export {
  ModalContent,
  stickyPositions,
  modalBackgrounds,
  modalWindowBackgrounds,
  modalWindowPositions,
};

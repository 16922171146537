
import React from 'react';
import Helmet from 'react-helmet';
import {TweenMax, TimelineMax, CSSPlugin, AttrPlugin, ScrollToPlugin} from 'gsap/all';
import {StaticQuery, graphql} from 'gatsby';
import Detector from 'utils/detector';
import Navbar from 'components/Navbar';
import 'styles/base.scss';

import favicon96 from '../img/favicon-96x96.png';
import favicon32 from '../img/favicon-32x32.png';
import favicon16 from '../img/favicon-16x16.png';
import ogImage from '../img/share-image.jpg';

export const AppSettings = React.createContext(0);

// without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [TweenMax, TimelineMax, CSSPlugin, AttrPlugin, ScrollToPlugin];

import increaseHeight from '../img/icon-increase-height@3x.png';
import increaseWidth from '../img/icon-increase-width@3x.png';
import rotateDevice from '../img/icon-rotate-device@3x.png';

const displayChangeMobile = 'displayChange/MOBILE';
const displayChangeWiden = 'displayChange/WIDEN';
const displayChangeLengthen = 'displayChange/LENGTHEN';

const defaultTitle = 'Selma Online';
const defaultDescription = 'A visual history of The Civil Rights Movement leading to The Voting Rights Act of 1965.';

const displayChangeMessages = {
  [displayChangeMobile]: {
    icon: rotateDevice,
    message: 'Please rotate your device to view.',
  },
  [displayChangeWiden]: {
    icon: increaseWidth,
    message: 'Please increase your browser’s width to view.',
  },
  [displayChangeLengthen]: {
    icon: increaseHeight,
    message: 'Please increase your browser’s height to view.',
  },
};

const browserMessages = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
};

class eventManager {
  constructor(el, eventName) {
    this.event = new Event(eventName);
    el.addEventListener(eventName, this.handleEvent.bind(this));
    this.el = el;
    this.eventName = eventName;
    this.subscribers = [];
  }

  subscribe(subscription) {
    this.subscribers.push(subscription);
  }

  unsubscribe(subscription) {
    const subscriptionIndex = this.subscribers.indexOf(subscription);
    if (subscriptionIndex > -1) {
      this.subscribers.splice(subscriptionIndex, 1);
    }
  }

  handleEvent() {
    this.subscribers.forEach((subscription) => {
      subscription();
    });
  }

  dispatch() {
    this.el.dispatchEvent(this.event);
  }
}

const Layout = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: 'light',
    };

    this.handleScreenResize = this.handleScreenResize.bind(this);
    this.dispatchScreenResize = this.dispatchScreenResize.bind(this);
    this.setAppState = this.setAppState.bind(this);

    if (typeof window !== `undefined`) {
      this.windowResizeHandler = new eventManager(window, 'throttledResize');
    }
  }

  componentDidMount() {
    this.setAppState();
    this.windowResizeHandler.subscribe(this.setAppState);
    window.addEventListener('resize', this.handleScreenResize);
  }

  componentWillUnmount() {
    this.windowResizeHandler.unsubscribe(this.setAppState);
    window.removeEventListener('resize', this.handleScreenResize);
  }

  handleScreenResize() {
    if (this.stateTimeout) {
      clearTimeout(this.stateTimeout);
    }

    this.stateTimeout = setTimeout(this.dispatchScreenResize, 100);
  }

  dispatchScreenResize() {
    this.windowResizeHandler.dispatch();
  }

  setAppState() {
    const newState = this.getScreenState();
    this.setState(Object.assign({}, this.state, newState));
  }

  getScreenState() {
    // have to wrap in this if statement to get thru Gatsby Build since window
    // doesn't exist at buildtime
    if (typeof window !== `undefined`) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit

      const {width, height} = this.app.getBoundingClientRect();
      const vh = height * 0.01;
      const vw = width * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);

      const screenAspectRatio = width / height;
      const lowerRatioLimit = 4/3;
      const upperRatioLimit = 3/1;
      const isLower = screenAspectRatio < lowerRatioLimit;
      const isHigher = screenAspectRatio > upperRatioLimit;

      const isMobile = Detector.isMobile || Detector.isTabvar;
      const userMustRotate = isMobile && (window.matchMedia('(orientation: portrait)').matches);
      const userMustWidenOrLengthen = !isMobile && (isLower || isHigher);

      let displayChangeMessage;
      if (isMobile && userMustRotate) {
        displayChangeMessage = displayChangeMobile;
      } else if (!isMobile && (isLower || isHigher)) {
        displayChangeMessage = isLower ? displayChangeWiden : displayChangeLengthen;
      }

      return {
        screenHeight: height,
        screenWidth: width,
        displayChangeMessage,
      };
    }
  }

  setTheme = (theme) => {
    this.setState({theme});
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
              site {
                siteMetadata {
                  title,
                  description,
                }
              }
            }
        `}
        render={(data) => {
          const {screenHeight, screenWidth, theme, displayChangeMessage} = this.state;
          const {title} = this.props;

          return (
            <AppSettings.Provider value={{
              screenHeight,
              screenWidth,
              displayChangeMessage,
              setTheme: this.setTheme,
              windowResizeHandler: this.windowResizeHandler,
            }}>
              <div ref={(ref) => this.app = ref} className="app">
                <Helmet>
                  <html lang="en" />
                  <title>{data.site.siteMetadata.title || defaultTitle}</title>
                  <meta name="description" content={data.site.siteMetadata.description || defaultDescription} />
                  <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-icon.png" />
                  <link rel="icon" type="image/png" href={favicon96} sizes="96x96" />
        	        <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
        	        <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />

        	        <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
        	        <meta name="theme-color" content="#202020" />
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:title" content={data.site.siteMetadata.title || defaultTitle} />
                  <meta name="twitter:description" content={data.site.siteMetadata.description || defaultDescription} />
                  <meta name="twitter:image" content={`https://www.selmaonline.org/${ogImage}`} />
        	        <meta property="og:type" content="website" />
                  <meta property="og:title" content={data.site.siteMetadata.title || defaultTitle} />
                  <meta property="og:url" content="/" />
                  <meta property="og:image" content={ogImage} />
                </Helmet>
                {screenHeight &&
                  <div className="page" id="mainPage">
                    {displayChangeMessage &&
                      <div className="screen-message">
                        <img
                          width={100}
                          src={displayChangeMessages[displayChangeMessage].icon}
                          alt={displayChangeMessages[displayChangeMessage].message}
                        />
                        <p>{displayChangeMessages[displayChangeMessage].message}</p>
                        <p className="alt">
                          Selma is a guided experience that is best viewed at
                          certain screen sizes.
                        </p>
                        {(Detector.isFacebook() || Detector.isInstagram()) &&
                          <p className="alt">
                            <b>IMPORTANT</b>:
                            Viewing this on the {Detector.isFacebook() ?
                              browserMessages.FACEBOOK :
                              browserMessages.INSTAGRAM} {' '}
                            app? Click the … in the top corner to view the full
                            experience in your device’s native browser.
                          </p>
                        }
                      </div>
                    }
                    {this.props.children}
                  </div>
                }
                <Navbar theme={theme} title={title} setTheme={this.setTheme}/>
              </div>
            </AppSettings.Provider>
          );
        }}
      />
    );
  }
};

export default Layout;

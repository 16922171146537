import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import 'styles/components/ftue-modal.scss';
import {Modal} from './Modal';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';

import iconKeyboard from '../img/ftue/icon-keyboard@2x.png';
import iconMouse from '../img/ftue/icon-mouse@2x.png';
import iconScreen from '../img/ftue/icon-screen-ui@2x.png';

const instructions = [
  {
    icon: iconScreen,
    iconWidth: 77,
    cta: 'Click',
    text: 'Use the on-screen arrow buttons',
    type: 'buttons',
  },
  {
    icon: iconKeyboard,
    iconWidth: 120,
    cta: 'Press',
    text: 'Use your keyboard’s arrow keys',
    type: 'keys',
  },
  {
    icon: iconMouse,
    iconWidth: 48,
    cta: 'Scroll',
    text: 'Use your mouse',
    type: 'mouse',
  },
];

export const FtueModal = class extends React.Component {
  onOpenClick() {}

  handleCloseClick = () => {
    trackCustomEvent({
      category: 'buttons',
      action: 'Click',
      label: 'Ftue Modal Continue button click',
    });
    this.modal.modalContent.onCloseClick();
  };

  render() {
    const {props} = this;
    const {destination} = props;

    return (
      <Modal ref={(ref) => this.modal = ref} className="ftue-modal" {...props}>
        <div className="ftue-modal__content">
          <div className="ftue-modal__header">
            <h2 className="uppercase">How to Use</h2>
            <h5 className="uppercase">3 Way to Navigate</h5>
          </div>
          <div className="ftue-modal__instructions">
            {instructions.map(({icon, iconWidth, cta, text, type}, index) => {
              return (
                <div
                  key={index}
                  className={`ftue-modal__instructions__method ${type}`}
                >
                  <div className="ftue-modal__instructions__method__image">
                    <img src={icon} width={iconWidth} />
                  </div>
                  <h3 className="highlight uppercase">{cta}</h3>
                  <p>{text}</p>
                </div>
              );
            })}
          </div>
          <div className="ftue-modal__footer">
            {destination &&
              <Link
                to={destination}
                className="button"
                aria-label="Continue">
                <button>Continue</button>
              </Link>
            }
            {!destination &&
              <button onClick={this.handleCloseClick.bind(this)}>Continue</button>
            }

          </div>
        </div>
      </Modal>
    );
  }
};

export default FtueModal;

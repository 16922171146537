import React from 'react';
import PropTypes from 'prop-types';
import QaContent from './QaContent';
import {AppSettings} from './Layout';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';

const QaButton = class extends React.Component {
  static contextType = AppSettings;
  constructor(props) {
    super(props);
    this.state = {
      isQaActive: false,
    };
    this.boundCloseQA = this.onCloseQA.bind(this);
  }

  componentDidUpdate() {
    const overflow = this.state.isQaActive ? 'hidden' : 'initial';
    document
      .querySelector('body')
      .setAttribute('style', `overflow: ${overflow}`);
  }

  componentWillUnmount() {
    document.querySelector('body').setAttribute('style', `overflow: initial`);
    if (this.state.isQaActive) {
      this.props.handleHideQA();
    }
  }

  onCloseQA() {
    trackCustomEvent({
      category: 'buttons',
      action: 'Click',
      label: `Close ${this.props.label} button click`,
    });
    this.setState({isQaActive: false});
    this.props.handleHideQA();
  }

  onOpenClick = () => {
    trackCustomEvent({
      category: 'buttons',
      action: 'Click',
      label: `Open ${this.props.label} button click`,
    });
    this.setState({isQaActive: true});
    this.props.handleShowQA();
  };

  render() {
    return (
      <div className={`qa-container ${this.props.className}`}>
        <button
          className={`qa-container__btn ${this.props.red ? '' : 'secondary'} medium`}
          onClick={this.onOpenClick}
          style={{lineHeight: 1.2}}>
          {this.props.label}
        </button>
        {this.state.isQaActive && (
          <QaContent {...this.props} onCloseQA={this.boundCloseQA} />
        )}
      </div>
    );
  }
};

QaButton.propTypes = {
  label: PropTypes.string.isRequired,
  continueLabel: PropTypes.string,
  continueCta: PropTypes.string,
  dataContent: PropTypes.array.isRequired,
};

export default QaButton;

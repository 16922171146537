
import React from 'react';
import ScrollManager from '../utils/ScrollManager';
import {throttled} from '../utils/index';

import 'styles/components/scroll-container.scss';

const scrollPadding = 50;

export const ScrollContainerContext = React.createContext();

const ScrollContainer = class extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.setScrollClass = this.setScrollClass.bind(this);
    this.scrollOverflow = 0;
    this.state = {
      imageLoad: false,
    };

    this.boundImageHasLoaded = this.imageHasLoaded.bind(this);
    this.boundDisableScroll = this.disableScroll.bind(this);
    this.boundEnableScroll = this.enableScroll.bind(this);
    this.handleScrollWheel = this.handleScrollWheel.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.resetScroll = this.resetScroll.bind(this);
  }

  componentDidMount() {
    this.scrollManager = new ScrollManager({
      target: this.el,
      onScrollWheel: this.handleScrollWheel,
      onKeyPress: this.handleScroll,
      onTouchMove: this.handleScroll,
      // onTouchEnd: this.handleTouchEnd
    });


    this.scrollManager.enable();

    if (!this.props.cancelScroll) {
      window.addEventListener('resize', this.setScrollClass);
      this.scrollRaf = requestAnimationFrame(this.setScrollClass);
    }

    const element = this.el.firstChild;
    if (this.props.shouldLog) {
      console.log(element);
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach( (mutation) => {
        if (mutation.type == 'attributes') {
          const isHidden = element.getAttribute('aria-hidden') === 'true';
          if (this.props.shouldLog) {
            console.log('isHidden', isHidden, this.childHidden);
          }
          if (isHidden && !this.state.childHidden) {
            if (this.props.shouldLog) {
              console.log('hiding');
            }
            this.setState({childHidden: true});
          } else if (!isHidden && this.state.childHidden) {
            if (this.props.shouldLog) {
              console.log('unhiding');
            }
            this.setState({childHidden: false});
          }
        }
      });
    });

    if (element) {
      observer.observe(element, {
        attributes: true, // configure it to listen to attribute changes
      });
    }
  }

  componentWillUnmount() {
    this.scrollManager.disable();
    this.clearOverflowResetTimeout();

    if (this.scrollRaf) {
      cancelAnimationFrame(this.scrollRaf);
    }
    window.removeEventListener('resize', this.setScrollClass);
  }

  getTotalChildrenHeight = (childElems) => {
    let totalChildHeight = 0;
    for (let i = 0; i < childElems.length; i++) {
      totalChildHeight += childElems[i].scrollHeight;
    }
    return totalChildHeight;
  }

  disableScroll() {
    this.setState({
      disabled: true,
    });
  }

  enableScroll() {
    this.setState({
      disabled: false,
    });
  }

  setScrollClass() {
    if (!this.el) return;
    if (this.scrollRaf) {
      this.scrollRaf = null;
    }
    const {clientHeight} = this.el;
    const childHeight = this.el.childElementCount
      ? this.getTotalChildrenHeight(this.el.children)
      : 0;

    if (this.props.shouldLog) {
      console.log(childHeight, clientHeight);
    }
    const canScroll = clientHeight < childHeight;
    if (canScroll) {
      this.setState({scrolls: true});
      // this.el.setAttribute('tabIndex', '0');
    } else {
      this.setState({scrolls: false});
      // this.el.removeAttribute('tabIndex');
    }
  }

  handleTouchMove(e) {
    handleScroll(e);
  }

  handleTouchEnd(e) {
    this.resetScroll();
  }

  handleScrollWheel(e) {
    this.clearOverflowResetTimeout();
    this.overflowResetTimeout = setTimeout(this.resetScroll, 250);
    this.handleScroll(e);
  }

  clearOverflowResetTimeout() {
    if (this.overflowResetTimeout) {
      clearTimeout(this.overflowResetTimeout);
    }
  }

  handleScroll(e) {
    const {scrollTop, scrollHeight, offsetHeight} = this.el;
    const canScroll = scrollHeight !== offsetHeight;
    if (canScroll) {
      const {deltaY} = e;

      const isAtTop = scrollTop <= 0;
      const isAtBottom = scrollTop >= (scrollHeight - offsetHeight);

      if (canScroll && isAtBottom) {
        this.scrollOverflow++;
      } else if (canScroll && isAtTop) {
        this.scrollOverflow++;
      } else {
        this.scrollOverflow = 0;
      }

      const isScrollingPastTop = deltaY < 0 && isAtTop && this.scrollOverflow > scrollPadding;
      const isScrollPastBottom = deltaY > 0 && isAtBottom && this.scrollOverflow > scrollPadding;

      if (!isScrollingPastTop && !isScrollPastBottom) {
        e.stopPropagation();
      }
    }
  }

  resetScroll() {
    if (this.overflowResetTimeout) {
      this.overflowResetTimeout = null;
    }

    if (this.scrollOverflow !== 0) {
      this.scrollOverflow = 0;
    }
  }

  imageHasLoaded = () => {
    // force render if there is an image inside the container
    if (!this) return;
    this.setState({imageLoad: !this.state.imageLoad});
  }

  render() {
    const {scrolls, childHidden, disabled} = this.state;
    const {className, alignLeft} = this.props;

    const classString = className ? className : '';
    const scrollsString = scrolls ? 'scrolls' : '';
    const alignmentString = alignLeft ? 'align-left' : '';
    const hiddenString = childHidden ? 'child-hidden' : '';
    const disabledString = disabled ? 'disabled' : '';

    const classes = `scroll-container ${scrollsString} ${classString} ${alignmentString} ${hiddenString} ${disabledString}`;

    return (
      <ScrollContainerContext.Provider value={{
        imageHasLoaded: this.boundImageHasLoaded,
        disableScroll: this.boundDisableScroll,
        enableScroll: this.boundEnableScroll,
      }}>
        <div ref={(ref) => this.el = ref} className={classes} ref={(ref) => this.el = ref}>
          {this.props.children}
        </div>
      </ScrollContainerContext.Provider>
    );
  }
};

export default ScrollContainer;

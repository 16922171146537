import React from 'react';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';

import SplitView from 'templates/SplitView';
import QaButton from 'components/QaButton';
import scene from 'components/scene';
import {Image} from 'components/Image';
import {ChapterPageContext} from 'components/chapter';
import {tabbable} from 'utils/accessibility';

import '../styles/components/chapter-end.scss';
import {Link} from 'gatsby';

const ChapterConclusion = class extends React.Component {
  static contextType = ChapterPageContext

  render() {
    const {chapterNumber, nextChapterTitle, questions, image, continueCta} = this.props;
    const cta = chapterNumber < 7 ? `/chapter-${chapterNumber + 1}` : '/conclusion';
    const contCta = continueCta || `CONTINUE TO ${chapterNumber === 7 ? 'CONCLUSION' : 'CHAPTER ' + (chapterNumber + 1)}`;

    return (
      <div className="chapter-end">
        <SplitView className="chapter-end__transition"
          fullWidthPanel={(
            image && <div className="chapter-end__transition__img">
              <Image image={image} alt={`Chapter ${chapterNumber} conclusion image`} sizes="100vw" />
            </div>
          )}
          leftPanel={(
            <div className="chapter-end__transition__text">
              <div {...tabbable}>
                <h3 className="highlight uppercase">Next Chapter:</h3>
                <h2 className="uppercase">{nextChapterTitle}</h2>
              </div>
              <div className="chapter-end__transition__text__buttons">
                {(questions && <QaButton
                  className="button-qa"
                  label={`Chapter ${chapterNumber} Q&A`}
                  continueLabel={contCta}
                  continueCta={cta}
                  dataContent={questions}
                  handleShowQA={this.context.actions.disableUi}
                  handleHideQA={this.context.actions.enableUi}
                />)}
                <Link
                  onClick={() => {
                    trackCustomEvent({
                      category: 'buttons',
                      action: 'Click',
                      label: `End of Chapter ${chapterNumber} continue click`,
                    });
                  }}
                  to={cta}
                  className={`link-button ${questions ? 'button-continue medium' : 'medium'}`}
                  aria-label={contCta}>
                  {contCta}
                </Link>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
};
export default scene(ChapterConclusion, {stopAtStart: false, stopAtEnd: true});

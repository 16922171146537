import React, {useEffect, useContext} from 'react';
import FullScreenContainer from 'components/FullScreenContainer';
import TweenMax, {Linear} from 'gsap/TweenMax';
import {ChapterPageContext, contentDirections} from 'components/chapter';

import 'styles/components/scene.scss';
import {AppSettings} from './Layout';
import PropTypes from 'prop-types';

const SceneLayout = class extends React.Component {
  static contextType = ChapterPageContext

  constructor(props) {
    super(props);
    const stops = this.createStops(props.stops);
    this.state = {
      stops,
      duration: props.duration,
      progress: 0,
    };

    this.moveContentForwards = this.moveContentForwards.bind(this);
    this.moveContentBackwards = this.moveContentBackwards.bind(this);
    this.moveContentTo = this.moveContentTo.bind(this);
    this.createStops = this.createStops.bind(this);
    this.registerSceneStops = this.registerSceneStops.bind(this);
    this.updateDuration = this.updateDuration.bind(this);
    this.handleMoveTween = this.handleMoveTween.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.jumpToStop = this.jumpToStop.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.sectionEndHandler = this.sectionEndHandler.bind(this);

    this.activeIndex = 0;
  }

  componentDidMount() {
    const {updates} = this.context;
    this.initScene();
    this.updates = updates;
    this.props.setTheme(this.props.theme);
    document.addEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress(e) {
    if (e.key.toLowerCase() === 'tab' && !this.state.tabMode) {
      this.setState({
        tabMode: true,
      });
    }
  }

  setFocus() {
    // console.log('set focus')
    // const focusableItems = this.focusArea.querySelectorAll(`*[tabindex='0']`)
    // console.log(focusableItems[0])
    // if (focusableItems && this.focusableItems !== focusableItems) {
    //   focusableItems[0].focus()
    // }
    // this.focusableItems = focusableItems
    this.focusArea.setAttribute('tabindex', 0);
    requestAnimationFrame( () => {
      this.focusArea.focus();
      this.focusArea.removeAttribute('tabindex');
    });
  }

  componentWillUnmount() {
    if (this.tween) {
      this.killMoveTween();
    }
    if (this.moveAnimationFrame) {
      cancelAnimationFrame(this.moveAnimationFrame);
    }
    if (this.tweenStartTimeout) {
      clearTimeout(this.tweenStartTimeout);
    }
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  componentDidUpdate(prevProps, prevState) {
    const {updates, activeIndex, state, context, props} = this;
    const {stops, tabMode} = state;
    const {contentDirection, actions} = context;

    if (stops) {
      const serializedPrevStops = JSON.stringify(prevState.stops);
      const serializedStops = JSON.stringify(stops);
      if (serializedPrevStops !== serializedStops) {
        if (activeIndex > stops.length) {
          this.setActiveIndex(stops.length);
        }
        requestAnimationFrame( () => {
          this.initScene();
        });
      }

      if (this.context.updates !== updates) {
        this.moveContent(contentDirection);
      }
    }
  }

  initScene() {
    const {stops} = this.state;
    const {stopAtStart, stopAtEnd} = this.props;
    const {actions, contentDirection, startingSection} = this.context;
    const {enableUi} = actions;

    const startingSectionExists = startingSection !== undefined && startingSection !== null;

    if (this.props.shouldLog) {
      console.log(startingSection, stopAtStart, 1, stops);
    }

    if (startingSectionExists && startingSection >= 0 && startingSection < stops.length) {
      requestAnimationFrame( () => {
        this.jumpToStop(startingSection);
        const shouldMoveFromStart = startingSection === 0 && !stopAtStart;
        const shouldMoveFromEnd = startingSection === stops.length - 1 && !stopAtEnd;
        if (shouldMoveFromStart || shouldMoveFromEnd) {
          this.moveContent(contentDirections.FORWARDS);
        }
      });
    } else {
      let condition;

      if (contentDirection === contentDirections.BACKWARDS) {
        this.jumpToStop(stops.length - 1);
        condition = !stopAtEnd;
      } else {
        this.jumpToStop(0);
        condition = !stopAtStart;
      }

      if (!this.moveAnimationFrame) {
        this.moveAnimationFrame = requestAnimationFrame( () => {
          this.moveAnimationFrame = null;
          if (condition) {
            this.moveContent(contentDirection);
          } else {
            // enableUi();
          }
        });
      }
    }
  }

  updateDuration(duration) {
    this.setState({
      duration,
    });
  }

  registerSceneStops(incomingStops) {
    const stops = this.createStops(incomingStops);
    this.setState({
      stops,
    });

    // registerStops(this.id, stopIds)
  }

  createStops(incomingStops) {
    const stops = [];

    let stopValues = [];
    if (incomingStops) {
      stopValues = incomingStops.map(({value}) => value);
      stops.push(...stopValues);
    }

    if (stopValues.length === 0 || !stopValues.includes(0)) {
      // console.log('adding stop at beginning')
      stops.splice(0, 0, 0); // creates a stop at the beginning
    }

    if (stopValues === 0 || !stopValues.includes(1)) {
      // console.log('adding stop at end')
      stops.push(1); // creates a stop at the end
    }

    return stops;
  }

  handleSceneLimitReached(direction) {
    const {changeScene, enableUi} = this.context.actions;

    if (changeScene) {
      changeScene(direction);
    }
  }

  moveContent(direction) {
    this.updates = this.context.updates;

    if (direction === contentDirections.FORWARDS) {
      this.moveContentForwards();
    } else if (direction === contentDirections.BACKWARDS) {
      this.moveContentBackwards();
    }
  }

  sectionEndHandler() {
    const {enableUi} = this.context.actions;
    enableUi();
  }

  moveContentBackwards() {
    const {activeIndex} = this;
    const {stops} = this.state;
    const {stopAtStart} = this.props;

    const sceneEndHandler = () => this.handleSceneLimitReached(contentDirections.BACKWARDS);

    if (activeIndex > 0) {
      const newActiveIndex = activeIndex - 1;
      const nextStopIsFirst = newActiveIndex === 0;
      const callback = nextStopIsFirst && !stopAtStart ? sceneEndHandler : this.sectionEndHandler;

      this.moveContentTo(newActiveIndex, contentDirections.BACKWARDS, callback);
    } else {
      sceneEndHandler();
    }
  }

  moveContentForwards() {
    const {activeIndex} = this;
    const {stops} = this.state;
    const {stopAtEnd} = this.props;

    const sceneEndHandler = () => this.handleSceneLimitReached(contentDirections.FORWARDS);

    if (activeIndex < stops.length - 1) {
      const newActiveIndex = activeIndex + 1;
      const nextStopIsLast = newActiveIndex === stops.length - 1;
      const callback = nextStopIsLast && !stopAtEnd ? sceneEndHandler : this.sectionEndHandler;

      this.moveContentTo(newActiveIndex, contentDirections.FORWARDS, callback);
    } else {
      sceneEndHandler();
    }
  }

  moveContentTo(index, contentDirection, callback) {
    if (this.tween) {
      this.killMoveTween();
    }

    const {progress, stops, duration: stateDuration} = this.state;
    const {duration: propsDuration, stopAtStart, stopAtEnd} = this.props;
    const {disableUi} = this.context.actions;
    const duration = stateDuration || propsDuration || 0.05;

    const stopValue = stops[index];
    const tweenDuration = Math.abs(stopValue - progress) * duration;

    disableUi();

    this.tween = TweenMax.to({progress}, tweenDuration, {progress: stopValue, onUpdate: this.handleMoveTween, onUpdateParams: ['{self}'], ease: Linear.easeNone, onStart: this.setFocus, onComplete: callback});
    this.tween.pause();

    const shouldDelayForwards = contentDirection === contentDirections.FORWARDS && this.activeIndex === 0 && !stopAtStart;
    const shouldDelayBackwards = contentDirection === contentDirections.BACKWARDS && this.activeIndex === stops.length - 1 && !stopAtEnd;
    const delayTweenStart = shouldDelayForwards || shouldDelayBackwards;

    if (delayTweenStart) {
      this.tweenStartTimeout = setTimeout(() => {
        this.tweenStartTimeout = null;
        this.tween.play();
      }, 1000);
    } else {
      this.tween.play();
    }

    this.setActiveIndex(index);
  }

  jumpToStop(index) {
    const {stops} = this.state;
    if (this.tween) {
      this.killMoveTween();
    }

    this.setActiveIndex(index);
    this.setState({
      progress: stops[index],
    });

    this.setFocus();
  }

  setActiveIndex(index) {
    const {stops} = this.state;

    this.activeIndex = index;

    if (index === 0) {
      this.context.actions.setPrevButton();
    } else if (index === stops.length -1) {
      this.context.actions.setNextButton();
    }
  }

  killMoveTween() {
    this.tween.kill();
  }

  handleMoveTween(tween) {
    this.updatePosition(tween.target.progress);
  }

  updatePosition(progress) {
    this.setState({progress});
  }

  render() {
    const {props, state, registerSceneStops, updateDuration, jumpToStop, moveContentBackwards, moveContentForwards} = this;
    const {progress} = this.state;
    const actions = {registerSceneStops, updateDuration, jumpToStop, moveContentBackwards, moveContentForwards};

    return (
      <FullScreenContainer className='scene'>
        <div ref={(ref) => this.focusArea = ref} />
        <div className="scene__container" role="main" >
          {React.cloneElement(
            props.children, {position: progress, actions, ...props}
          )}
        </div>
      </FullScreenContainer>
    );
  }
};

SceneLayout.defaultProps = {
  theme: 'light',
};

const SceneWrapper = (props) => {
  const {setTheme} = useContext(AppSettings);
  return (
    <SceneLayout setTheme={setTheme} {...props}>
      {props.children}
    </SceneLayout>
  );
};

const scene = (Component, {
  stopAtStart=false,
  stopAtEnd=true,
  stops,
  duration,
  shouldLog}={}
) => {
  return class extends React.Component {
    render() {
      return (
        <SceneWrapper
          stopAtStart={stopAtStart}
          stopAtEnd={stopAtEnd}
          stops={stops}
          duration={duration}
          shouldLog={shouldLog}
          {...this.props}
        >
          <Component />
        </SceneWrapper>
      );
    }
  };
};

export default scene;

import React from 'react';
import PropTypes from 'prop-types';

import FullScreenContainer from './FullScreenContainer';
import ScrollContainer from './ScrollContainer';
import Image from './Image';
import Modal from './Modal';
import { stickyPositions, modalWindowBackgrounds } from './ModalContent';
import ScenePlayer from 'templates/ScenePlayer';
import {tabbable} from 'utils/accessibility'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {hideMainPage, showMainPage} from 'utils'

import 'styles/components/movie-scene.scss';

import Play from '../img/ui-round.svg';
import Replay from '../img/ui-round-replay.svg';

const MovieScene = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoPlaying: false,
      hasVideoCompleted: false
    };
  }

  toggleVideo = (e, hasVideoCompleted = false) => {
    const { isVideoPlaying } = this.state

    if (isVideoPlaying) {
      showMainPage();
    } else {
      hideMainPage()
      requestAnimationFrame( () => {
        this.player.focus()
      })
    }

    this.setState({
      isVideoPlaying: !isVideoPlaying,
      hasVideoCompleted
    });
  };

  render() {
    const {
      firstImage,
      lastImage,
      sceneNumber,
      sceneDescription,
      noSound,
      sceneSubtitle,
      playerTitle,
      modalComponent,
      videoSrc,
      modalBg,
    } = this.props;
    const { hasVideoCompleted, isVideoPlaying } = this.state;
    const canTab = isVideoPlaying ? {} : tabbable

    return (
      <FullScreenContainer>
        <div className="movie-scene">
          <div className="movie-scene__bkg">
            <div
              className={`bkg-img${isVideoPlaying ? ' is-playing' : ''}`}
              hidden={hasVideoCompleted}>
              <Image
                image={firstImage}
                sizes="100vw"
                alt={`${sceneDescription}`}
              />
            </div>
            <div
              className={`bkg-img${isVideoPlaying ? ' is-playing' : ''}`}
              hidden={!hasVideoCompleted}>
              <Image
                image={lastImage}
                sizes="100vw"
                alt={`${sceneDescription}`}
              />
            </div>
            <ScrollContainer className="content__container">
              <div className="content">
                <div className="caption">
                  <h4 {...canTab}>{sceneSubtitle}</h4>
                  <h2 {...canTab}>
                    {sceneDescription}{' '}
                    {modalComponent && (
                      <Modal
                        className="player-modal"
                        stickyPosition={stickyPositions.NO_STICKY}
                        modalWindowBackground={
                          modalBg ? modalBg : modalWindowBackgrounds.WHITE
                        }>
                        {modalComponent()}
                      </Modal>
                    )}
                  </h2>
                  <div className="strong-lang">
                    <div className="strong-lang__warning">
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <g fill="none" fillRule="evenodd" stroke="#fc1930" strokeWidth="2">
                          <path d="M18 5l13 26H5zM18 14v10M18 26v2"/>
                        </g>
                      </svg>
                    </div>
                    <div className="strong-lang__message">
  <p {...canTab} className="alt">Some content depicts violence and contains language that may be upsetting to users. Discretion advised.{noSound && ` Portions of this clip do not contain sound.`}</p>
                    </div>
                  </div>
                </div>
                <div className="player-trigger">
                  <button
                    name="Play Button"
                    type="button"
                    aria-label="Play Button"
                    onClick={this.toggleVideo}>
                    {!hasVideoCompleted ? <Play /> : <Replay />}
                  </button>
                </div>
              </div>
            </ScrollContainer>
          </div>

          <div ref={ref => this.player = ref}>
      			<TransitionGroup>
              {isVideoPlaying && (
        				<CSSTransition
                  key={isVideoPlaying}
                  timeout={500}
                  classNames="movie-scene__transition"
                >

                  <ScenePlayer
                    sceneNumber={sceneNumber}
                    playerTitle={playerTitle || sceneSubtitle}
                    sceneDescription={sceneDescription}
                    videoSrc={videoSrc}
                    onClose={this.toggleVideo}
                    controls
                    playsinline={true}
                  />
        				</CSSTransition>
              )}
              {!isVideoPlaying && <div />}
      			</TransitionGroup>
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

MovieScene.propTypes = {
  sceneNumber: PropTypes.number,
  sceneDescription: PropTypes.string,
  firstImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  lastImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  videoSrc: PropTypes.string,
  modalComponent: PropTypes.func,
  modalBg: PropTypes.string,
};

export default MovieScene;

import React from 'react';
import PropTypes from 'prop-types';
import Image from '../components/Image';
import scene from 'components/scene';
import FullScreenContainer from '../components/FullScreenContainer';
import '../styles/templates/chapter-hero.scss';
import FullScreenVideoPlayer from 'components/FullScreenVideoPlayer';
import TweenMax, { Expo } from 'gsap/TweenMax';
import {tabbable} from 'utils/accessibility'

const ChapterIntro = class extends React.Component {
  constructor(props) {
    super(props);
    this.boundHandleVideoLoaded = this.handleVideoLoaded.bind(this);
    this.boundHandleVideoUpdate = this.handleVideoUpdate.bind(this);
    this.state = { videoLoaded: this.props.video ? false : true };
  }

  componentDidMount() {
    if (!this.state.videoLoaded) {
      console.log('intro setting title opacity to 0')
      TweenMax.set(this.text, { opacity: this.props.video ? 0 : 1 });
    }
  }

  handleVideoUpdate () {
    if (!this.state.videoLoaded) {
      this.handleVideoLoaded()
    }
  }

  handleVideoLoaded() {
    this.setState({ videoLoaded: true });

    TweenMax.to(this.text, 1, {
      opacity: 1,
      delay: 0.5,
      ease: Linear.easeNone
    });
  }
  render() {
    const { title, subtitle, hero, alt, video } = this.props;
    // console.log(this.props.progress)
    return (
      <FullScreenContainer>
        <div className="chapter-hero" ref={ref => (this.self = ref)}>
          {hero && (
            <Image
              image={hero}
              className="chapter-hero__image"
              alt={alt || `${title} hero image`}
              sizes="100vw"
              showAria
            />
          )}
          {video && (
            <FullScreenVideoPlayer
              src={video}
              autoplay
              muted
              playsinline
              onVideoLoaded={this.boundHandleVideoLoaded}
              onVideoUpdate={this.boundHandleVideoUpdate}
            />
          )}
          <div className="chapter-hero__content" ref={ref => (this.text = ref)}>
            <h4 {...tabbable} className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h4>
            {/* <Doodle className="doodle" /> */}

            <svg
              className={`doodle ${this.state.videoLoaded ? 'visible' : ''}`}
              viewBox="0 0 390.7 98"
              preserveAspectRatio="none"
              stroke="red"
              strokeWidth={5}
              strokeLinecap="round"
              fill="none">
              <path
                className="st0"
                d="M390.5,78c0,0-101,6-199,6s-179-14-188-42s107-39,186-39s191,18,191,40s-77,52-193,52s-182-51-90-68 s247,8,259,13"
              />
            </svg>
            <h1 {...tabbable} >{subtitle}</h1>
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

ChapterIntro.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default scene(ChapterIntro, { stopAtStart: true, stopAtEnd: false });

import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/image.scss';
import { ScrollContainerContext } from './ScrollContainer';

export const Image = React.forwardRef((props, ref) => {

  const useGatsbyImage = !!props.image && !!props.image.placeholder
  const useCaptionedImage = !useGatsbyImage && props.caption

  return useGatsbyImage ? <PlaceholderImage {...props} /> : useCaptionedImage ? renderCaptionedImage(props) : renderImage(props)
});


const renderImage = ({image, className, style, alt, sizes, width, height, showAria}, onload) => {
  const src = !!image.childImageSharp
    ? image.childImageSharp.fluid.src
    : image;

  const srcset = !!image && !!image.childImageSharp
    ? image.childImageSharp.fluid.srcSet
    : null;

  return (
    <img sizes={sizes} className={className} style={style} srcSet={srcset} src={src} alt={alt} width={width} height={height} onLoad={onload} aria-hidden={showAria ? false : true} tabIndex={showAria ? 0 : -1}/>
  )
}

const renderCaptionedImage = (props) => {
  return (
    <div className="image-container">
      {renderImage(props)}
      {renderCaption(props.caption)}
    </div>
  )
}

const renderCaption = (caption) => {
  return (
    <div className="image-caption" aria-hidden={true}>
      <p className="image-caption__caption alt">
        {caption}
      </p>
    </div>
  )
}

const PlaceholderImage = class extends React.Component {
  static contextType = ScrollContainerContext

  constructor(props) {
    super(props)

    this.state={
      showImage: false
    }

    this.handleImageLoaded = this.handleImageLoaded.bind(this)
  }

  componentWillUnmount () {
    this.handleImageLoaded = null
  }

  handleImageLoaded() {
    if (this.shouldLog) {
      console.log(this.context)
    }
    if (this.context && this.context.imageHasLoaded) {
      this.context.imageHasLoaded()
    }
    this.setState({
      showImage: true
    })
  }


  render () {
    const {showImage} = this.state
    const {image, className, style, alt, sizes, width, height, caption} = this.props
    const placeholderClass = `image-container__image placeholder ${className || ''} ${showImage ? 'hide-image' : 'reveal-image'}`
    const imageClass = `image-container__image full-res ${showImage ? 'reveal-image' : 'hide-image'}`
    if (this.shouldLog) {
      console.log('rendering image')
    }
    return (
      <div className={`image-container`}>
        <img sizes={sizes} className={placeholderClass} style={style} src={image.placeholder.fluid.base64} width={width} height={height} aria-hidden={true} />

        <div className={imageClass}>
          {renderImage(this.props, this.handleImageLoaded)}
          <div className='focus-outline'/>
          {caption &&
            renderCaption(caption)
          }
        </div>
      </div>
    )
  }
}

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  style: PropTypes.object,
  className: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Image;

import React from 'react';
import {ChapterPageContext} from 'components/chapter';
import Modal from "./Modal"

const ChapterModal = class extends React.Component {
  static contextType = ChapterPageContext;

  onOpenClick() {
    const {uiEnabled = true, actions} = this.context || {};

    if (!uiEnabled) {
      return false;
    }

    if (actions) {
      actions.disableUi();
    }

    return uiEnabled
  }

  onCloseClick = () => {
    const {actions} = this.context || {};

    if (actions) {
      actions.enableUi();
    }
  };

  render() {
    const {props, context} = this;
    const {uiEnabled = true} = context || {};
    const enabledVal = uiEnabled ? 'enabled' : 'disabled';

    return <Modal
      onOpenClick={this.onOpenClick.bind(this)}
      onCloseClick={this.onCloseClick.bind(this)}
      {...props} />;
  }
};

export default ChapterModal;

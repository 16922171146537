import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {TweenMax, TimelineMax} from 'gsap';
import VideoPlayer from 'components/VideoPlayer';
import {tabbable} from 'utils/accessibility';

import '../styles/templates/scene-player.scss';

import Pause from '../img/pause.svg';
import Play from '../img/Play.svg';
import SkipForwardUI from '../img/ui/skip_forward.svg';
import SkipBackUI from '../img/ui/skip_back.svg';

import {showElement, hideElement} from 'utils/animation';

const ScenePlayer = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: true,
      isCompleted: false,
      minutes: '0',
      seconds: '00',
    };

    this.videoPlayerContainer = document.createElement('div');
    this.bodyContainer = document.querySelector('body');
    document.body.appendChild(this.videoPlayerContainer);

    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.onVideoLoadedMetaData = this.onVideoLoadedMetaData.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.onSkipForward = this.onSkipForward.bind(this);
    this.onSkipBack = this.onSkipBack.bind(this);
  }

  componentDidMount() {
    this.bodyContainer.setAttribute('style', 'overflow: hidden');
    this.setPlayerInitialState();
    this.setPlayerTweens();
    this.prepareCaptionHide();

    this.progress.addEventListener('click', this.onProgressClick);
    [this.playerContainer, this.background].map((target) =>
      target.addEventListener('mouseover', this.showCaption.bind(this)));
  }

  componentWillUnmount() {
    this.bodyContainer.setAttribute('style', 'overflow: initial');
    this.playerTimeline.kill();
    [this.playerContainer, this.background].map((target) =>
      target.removeEventListener('mouseover', this.onProgressClick));

    document.body.removeChild(this.videoPlayerContainer);

    this.progress.removeEventListener('click', this.onProgressClick);
  }

  prepareCaptionHide() {
    if (this.timer) clearTimeout(this.timer);

    this.timer = setTimeout(() =>
      this.topCaption && this.topCaption.childNodes.forEach( (elem) =>
        TweenMax.to(elem, 1, {opacity: 0})), 3000);
  }

  showCaption() {
    this.topCaption.childNodes.forEach((elem) =>
      TweenMax.to(elem, 2, {opacity: 1}));
    this.prepareCaptionHide();
  }

  setPlayerInitialState = () => {
    this.playerElems = [this.playerContainer, this.topCaption, this.controls];
    this.playerElems.forEach((elem) => {
      TweenMax.set(elem, {opacity: 0});
    });
  };

  setPlayerTweens = () => {
    this.playerTimeline = new TimelineMax({
      paused: true,
      onReverseComplete: this.onReverseComplete,
    });

    const upOffset = '-=20';
    const downOffset = '+=20';

    this.playerTimeline
      .fromTo(this.playerContainer, 0.3, {opacity: 0}, {opacity: 1})
      .fromTo(
        this.topCaption,
        0.3,
        {opacity: 0, y: upOffset},
        {opacity: 1, y: downOffset}
      )
      .fromTo(
        this.controls,
        0.3,
        {opacity: 0, y: downOffset},
        {opacity: 1, y: upOffset},
        '-=0.3'
      )
      .play();
  };

  onVideoLoadedMetaData = (player) => {
    this.progress.setAttribute('max', player.duration);
    this.setState({player});
  };

  onVideoUpdate = () => {
    const {player} = this.state;
    this.progress.value = player.currentTime;
    if (player.duration && player.duration.toString() != this.progress.getAttribute('max')) {
      console.log('fixing');
      this.progress.setAttribute('max', player.duration);
    }

    const minutes = parseInt(player.currentTime / 60, 10).toString();
    const parsedSeconds = parseInt(player.currentTime % 60);
    const seconds =
      parsedSeconds < 10 ? '0' + parsedSeconds : parsedSeconds.toString();

    this.setState({minutes, seconds});
  };

  onVideoEnded = () => {
    this.setState({isCompleted: true});
    this.onCloseClick();
  };

  onProgressClick = (e) => {
    const {player} = this.state;
    const {x, width} = this.progress.getBoundingClientRect();

    const pos =
      (e.pageX - x) / width;
    player.currentTime = pos * player.duration;
  };

  onPlayClick = () => {
    const {player} = this.state;
    let isPlaying;

    if (player.paused || player.ended) {
      player.play();
      isPlaying = true;
    } else {
      player.pause();
      isPlaying = false;
    }

    this.setState({isPlaying});
  };

  onCloseClick = () => {
    this.playerTimeline.reverse();
  };

  onSkipBack = () => {
    const {player} = this.state;

    const t = player.currentTime < 30 ? 0 : player.currentTime - 30;
    player.currentTime = t;
  };

  onSkipForward = () => {
    const {player} = this.state;
    const t = player.currentTime + 30 >= player.duration ? player.duration : player.currentTime + 30;
    player.currentTime = t;
  };

  onReverseComplete = () => {
    this.props.onClose({}, this.state.isCompleted);
  };

  render() {
    const {sceneNumber, sceneDescription, playerTitle, videoSrc} = this.props;
    const {minutes, seconds, isPlaying} = this.state;

    return ReactDOM.createPortal(
      <div className="video-player" ref={(ref) => (this.background = ref)}>
        <div className="top-caption" ref={(ref) => (this.topCaption = ref)}>
          <div className="caption-text">
            <h4 className="red">
              {playerTitle}
            </h4>
            <h4 className="white">{sceneDescription || ''}</h4>
          </div>
          <button className="close-btn" aria-label="close" onClick={this.onCloseClick}>
            <div className="x-stick" />
          </button>
        </div>

        <div className="video-container">
          <div ref={(ref) => (this.playerContainer = ref)}>
            <VideoPlayer
              src={videoSrc}
              autoplay={true}
              onVideoLoadedMetaData={this.onVideoLoadedMetaData}
              onVideoEnded={this.onVideoEnded}
              onVideoUpdate={this.onVideoUpdate}
              {...this.props}
            />
          </div>
          <div className="controls-wrapper">
            <div className="controls" ref={(ref) => (this.controls = ref)}>
              <div className='button-container'>
                <button className="rewind-button" aria-label="rewind" onClick={this.onSkipBack}>
                  <SkipBackUI />
                </button>
                <button className="play-button" aria-label="play/pause toggle" onClick={this.onPlayClick}>
                  {isPlaying ? (
                    <Pause className="pause" />
                  ) : (
                    <Play className="play" />
                  )}
                </button>
                <button className="forward-button" aria-label="fast forward" onClick={this.onSkipForward}>
                  <SkipForwardUI />
                </button>
              </div>

              <progress
                className="progress"
                ref={(ref) => (this.progress = ref)}
              />
              <div className="time caption">
                {minutes}:{seconds}
              </div>
            </div>
          </div>
        </div>
        <div className="video-bottom"></div>
      </div>,
      this.videoPlayerContainer
    );
  }
};

ScenePlayer.propTypes = {
  sceneNumber: PropTypes.number,
  sceneDescription: PropTypes.string,
  videoSrc: PropTypes.string,
  onClose: PropTypes.func,
};

export default ScenePlayer;

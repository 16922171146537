import {TweenMax, TimelineMax} from 'gsap';
import {tabbable, untabbable} from './accessibility'

const placeholder = (timelineMaxInstance, time = 0.03) =>
  timelineMaxInstance.to({ph: 0}, time, {ph: 1});

const hideElement = {
  autoAlpha: 0,
}

const showElement = {
  autoAlpha: 1,
}

const removeTab = {
  attr: {'tabindex': '-1'}
}

const addTab = {
  attr: {'tabindex': '0'}
}

const hideElementWithAria = {
  autoAlpha: 0, attr: {'aria-hidden': true}
}

const showElemWithAria = {
  autoAlpha: 1, attr: {'aria-hidden': false}
}

const makeElementTabbable = {
  attr: {...tabbable, 'aria-hidden': false}
}

const unmakeElementTabbable = {
  attr: {...untabbable, 'aria-hidden': true}
}

const hideFromBelow = {
  ...hideElementWithAria, y: 50
}

const revealFromBelow = {
  ...showElemWithAria, y: 0
}

const setThenRemoveFocus = (el) => {
  el.setAttribute('tabindex', 0)
  el.focus()
  el.removeAttribute('tabindex', 0)
}

const createDarkThemeOnStartSetter = (setTheme) => {
  return {
    onStart: () => setTheme('dark'),
    onReverseComplete: () => setTheme('light')
  }
}

const createLightThemeOnStartSetter = (setTheme) => {
  return {
    onStart: () => setTheme('light'),
    onReverseComplete: () => setTheme('dark')
  }
}

const hideAllRefs = (refs, exclude=[]) => {
  for (const ref in refs) {
    if (exclude.length) {
      if (exclude.indexOf(ref) < 0) {
        TweenMax.set(refs[ref], {...hideElementWithAria});
      }
    } else {
      TweenMax.set(refs[ref], {...hideElementWithAria});
    }
  }
};

export const createTimelineMarker = (timeline, id) => {
  const value = timeline.duration
  return {id, value}
}

export const linear = (t) => { return t }
// accelerating from zero velocity
export const easeInQuad = (t) => { return t*t }
// decelerating to zero velocity
export const easeOutQuad = (t) => { return t*(2-t) }
// acceleration until halfway, then deceleration
export const easeInOutQuad = (t) => { return t<.5 ? 2*t*t : -1+(4-2*t)*t }
// accelerating from zero velocity
export const easeInCubic = (t) => { return t*t*t }
// decelerating to zero velocity
export const easeOutCubic = (t) => { return (--t)*t*t+1 }
// acceleration until halfway, then deceleration
export const easeInOutCubic = (t) => { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
// accelerating from zero velocity
export const easeInQuart = (t) => { return t*t*t*t }
// decelerating to zero velocity
export const easeOutQuart = (t) => { return 1-(--t)*t*t*t }
// acceleration until halfway, then deceleration
export const easeInOutQuart = (t) => { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t }
// accelerating from zero velocity
export const easeInQuint = (t) => { return t*t*t*t*t }
// decelerating to zero velocity
export const easeOutQuint = (t) => { return 1+(--t)*t*t*t*t }
// acceleration until halfway, then deceleration
export const easeInOutQuint = (t) => { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t }

export {addTab, removeTab, placeholder, hideAllRefs, showElement, hideElement, showElemWithAria, hideElementWithAria, makeElementTabbable, unmakeElementTabbable, setThenRemoveFocus, hideFromBelow, revealFromBelow, createDarkThemeOnStartSetter, createLightThemeOnStartSetter};

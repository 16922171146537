import React from 'react';
import {FacebookShareButton, TwitterShareButton} from 'react-share';
import TwitterImg from '../img/landing/twitter.svg';
import FbImg from '../img/landing/facebook.svg';

const shareUrl = 'http://bit.ly/SelmaOnline';
const shareText = '#Parents & #teachers – looking for free, engaging educational materials for middle and high school students? Download #SelmaOnline, a new platform that teaches students about the historic 1965 Selma voting rights campaign & its impact on our society today.';

export const FacebookShare = ({tabIndexValue}) => {
  return (
    <FacebookShareButton
      url={shareUrl}
      quote={shareText}
      tabIndex={tabIndexValue || 0}
    >
      <FbImg />
    </FacebookShareButton>
  );
};

export const TwitterShare = ({tabIndexValue}) => {
  return (
    <TwitterShareButton
      url={shareUrl}
      title={shareText}
      tabIndex={tabIndexValue || 0}
    >
      <TwitterImg />
    </TwitterShareButton>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import FullScreenContainer from '../components/FullScreenContainer';
import ScrollContainer from 'components/ScrollContainer';
import '../styles/templates/split-view.scss';

export const viewTypes = {
  EVEN: 'even',
  ONE_THIRD: 'one-third',
  TWO_THIRDS: 'two-thirds',
};

const SplitView = class extends React.Component {
  render() {
    const {
      viewType = viewTypes.EVEN,
      fullWidthPanel,
      leftPanel,
      rightPanel,
      className,
      inheritHeight,
      leftPanelShouldLog,
      rightPanelShouldLog,
      shouldCancelLeftScroll,
      shouldCancelRightScroll,
      leftPanelAlignRight
    } = this.props;

    return (
      <FullScreenContainer inheritHeight={inheritHeight}>
        <div className={`split-view ${viewType} ${className ? className : ''}`}>
          {fullWidthPanel &&
           <div className='split-view__panel full-width'>
             {fullWidthPanel}
           </div>
          }
          <ScrollContainer cancelScroll={shouldCancelLeftScroll} shouldLog={leftPanelShouldLog} alignLeft={!leftPanelAlignRight} className="split-view__panel left">
            {leftPanel}
          </ScrollContainer>
          <ScrollContainer cancelScroll={shouldCancelRightScroll} shouldLog={rightPanelShouldLog} className="split-view__panel right">
            {rightPanel}
          </ScrollContainer>
        </div>
      </FullScreenContainer>
    );
  }
};

SplitView.propTypes = {
  viewType: PropTypes.string,
  leftPanel: PropTypes.element,
  rightPanel: PropTypes.element,
};

export default SplitView;

import {easeInOutCubic} from './animation'

export const getPercentageBetweenBounds = (amount, min, max) => {
  const top = (Math.min(max, Math.max(min, amount)) - min);
  const bottom = (max - min);
  return top / bottom;
};

export const mapToRange = (num, in_min, in_max, out_min, out_max) => {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export const getPositionFromValues = (position, inValues, outValues, params={}) => {
  const {min=0,max=1,easeFunction=easeInOutCubic} = params
  let index = -1
  inValues.forEach((value,valueIndex) => {
    if (position > value) {
      index = valueIndex
    }
  })
  const isFirst = index === -1
  const isLast = index === inValues.length - 1

  const inMin = isFirst ? min : inValues[index]
  const inMax = isLast ? max : inValues[(isFirst ? 0 : index + 1)]
  const outMin = !!outValues ? isFirst ? min : outValues[index] : inMin
  const outMax = !!outValues ? isLast ? max : outValues[index + 1] : inMax

  const mappedPosition = mapToRange(position, inMin, inMax, min, max)
  const easedPosition = outMin + (easeFunction(mappedPosition) * (outMax - outMin))

  return easedPosition
}

export const ordinalSuffixOf = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const dateDifference = (date1, date2) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Get an element's distance from the top of the page
export const distanceFromDocumentTop = ( elem ) => {
  let location = 0;
  if (elem.offsetParent) {
    do {
      location += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }
  return location >= 0 ? location : 0;
};

// clip path support, from https://codepen.io/anon/pen/YXyyMJ
export const areClipPathShapesSupported = () => {
  const base = 'clipPath';
  const prefixes = ['webkit', 'moz', 'ms', 'o'];
  const properties = [base];
  const testElement = document.createElement( 'testelement' );
  const attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)';

  // Push the prefixed properties into the array of properties.
  for ( let i = 0, l = prefixes.length; i < l; i++ ) {
    const prefixedProperty = prefixes[i] + base.charAt( 0 ).toUpperCase() + base.slice( 1 ); // remember to capitalize!
    properties.push( prefixedProperty );
  }

  // Interate over the properties and see if they pass two tests.
  for ( let i = 0, l = properties.length; i < l; i++ ) {
    const property = properties[i];

    // First, they need to even support clip-path (IE <= 11 does not)...
    if ( testElement.style[property] === '' ) {
      // Second, we need to see what happens when we try to create a CSS shape...
      testElement.style[property] = attribute;
      if ( testElement.style[property] !== '' ) {
        return true;
      }
    }
  }
  return false;
};

// from https://stackoverflow.com/a/2901298
export const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const throttled = (delay, fn) => {
  let lastCall = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}

export const queryParamsToObject = () => {
  const search = location.search.substring(1);
  if (!search) return
  return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
}

export const hideMainPage = () => {
  const mainPage = document.getElementById('mainPage')
  mainPage.setAttribute('aria-hidden', 'true')
}

export const showMainPage = () => {
  const mainPage = document.getElementById('mainPage')
  mainPage.setAttribute('aria-hidden', 'false')
}

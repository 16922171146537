import React from 'react';
import PropTypes from 'prop-types';
import FullScreenContainer from 'components/FullScreenContainer';
import VideoPlayer from 'components/VideoPlayer';

import 'styles/components/full-screen-video-player.scss';

const FullScreenVideoPlayer = class extends React.Component {
  render() {
    return (
      <FullScreenContainer className="full-screen-video-player">
        <VideoPlayer {...this.props} />
      </FullScreenContainer>
    );
  }
};

FullScreenVideoPlayer.propTypes = {

};

export default FullScreenVideoPlayer;

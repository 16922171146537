import React from 'react';
import PropTypes from 'prop-types';
import {tabbable} from 'utils/accessibility'

const VideoPlayer = class extends React.Component {
  componentDidMount() {
    // this.videoEl.pause();

    // HACK - muted property not getting added to video el, manually adding here
    if (this.props.muted) {
      // console.log('mute it')
      this.videoEl.setAttribute('muted', "")
    }

    requestAnimationFrame( () => {
      this.addPlayerEvents();
    });
  }

  componentWillUnmount() {
    this.removePlayerEvents();
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.replayVideo && !this.props.replayVideo) {
      const isVideoPlaying =  !!(this.videoEl.currentTime > 0 && !this.videoEl.paused && !this.videoEl.ended && this.videoEl.readyState > 2);

      if (!isVideoPlaying) {
        this.videoEl.currentTime = 0;
        this.videoEl.play();
      }
    }
  }

  addPlayerEvents = () => {
    this.tempVideo = document.createElement('video');
    this.tempVideo.addEventListener('canplaythrough', this.onVideoLoaded);
    this.tempVideo.addEventListener('loadedmetadata', this.onVideoLoadedMetaData);
    this.tempVideo.src = this.props.src;
    this.videoEl.addEventListener('timeupdate', this.onVideoUpdate);
    this.videoEl.addEventListener('ended', this.onVideoEnded);
  }

  removePlayerEvents = () => {
    this.tempVideo.removeEventListener('canplaythrough', this.onVideoLoaded);
    this.tempVideo.removeEventListener('loadedmetadata', this.onVideoLoadedMetaData);
    this.videoEl.removeEventListener('timeupdate', this.onVideoUpdate);
    this.videoEl.removeEventListener('ended', this.onVideoEnded);
  }

  onVideoLoaded = () => {
    const {onVideoLoaded, autoplay, muted} = this.props;

    if (onVideoLoaded) {
      onVideoLoaded(this.videoEl);
    }

    if (autoplay) {
      this.videoEl.play();
    }
  }

  onVideoLoadedMetaData = () => {
    const {onVideoLoadedMetaData} = this.props;
    if (onVideoLoadedMetaData) {
      onVideoLoadedMetaData(this.videoEl);
    }
  }

  onVideoUpdate = () => {
    const {onVideoUpdate} = this.props;
    if (onVideoUpdate) {
      onVideoUpdate(this.videoEl);
    }
  }

  onVideoEnded = () => {
    const {onVideoEnded} = this.props;
    if (onVideoEnded) {
      onVideoEnded(this.videoEl);
    }
  }

  render() {
    const {src, muted, playsinline, loop, autoplay, alt} = this.props;
    const canTab = alt ? tabbable : {}
    return (
      <div aria-label={alt} {...canTab} className="video">
        <video ref={(ref) => this.videoEl = ref} className="player" loop={loop} muted={muted} playsInline={playsinline} autoPlay={autoplay}>
          <source src={src} type="video/mp4"/>
        </video>
      </div>
    );
  }
};

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  onVideoLoaded: PropTypes.func,
  onVideoLoadedMetaData: PropTypes.func,
  onVideoUpdate: PropTypes.func,
  onVideoEnded: PropTypes.func,
};

export default VideoPlayer;

import React from 'react';
import PropTypes from 'prop-types';
import FullScreenContainer from 'components/FullScreenContainer';
import Image from 'components/Image';

import {AppSettings} from 'components/Layout'

import 'styles/components/full-screen-image.scss';

const sizeStrings = {
  FULL_WIDTH: 'full-width',
  FULL_HEIGHT: 'full-height'
}

const FullScreenImage = class extends React.Component {
  static contextType = AppSettings

  constructor( props) {
    super(props);

    this.state = {
      isAspectRatio: false,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.checkAspectRatio(this.props.aspectRatio);

    this.context.windowResizeHandler.subscribe(this.handleResize)
  }

  componentWillUnmount() {
    this.context.windowResizeHandler.unsubscribe(this.handleResize)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.aspectRatio !== this.props.aspectRatio) {
      this.checkAspectRatio(nextProps.aspectRatio);
    }
  }

  handleResize() {
    this.checkAspectRatio(this.props.aspectRatio);
  }

  checkAspectRatio(aspectRatio) {
    const {innerWidth, innerHeight} = window;
    const isAspectRatio = innerWidth / innerHeight >= aspectRatio;

    requestAnimationFrame(() => {
      this.setState({isAspectRatio});
    })
  }

  determineSizeString (isAspectRatio, containImage) {
    const sizeStringIfAspectRatio = containImage ? sizeStrings.FULL_HEIGHT : sizeStrings.FULL_WIDTH;
    const sizeStringIfNotAspectRatio = containImage ? sizeStrings.FULL_WIDTH : sizeStrings.FULL_HEIGHT;
    return isAspectRatio ? sizeStringIfAspectRatio : sizeStringIfNotAspectRatio;
  }

  render() {
    const {image, alt, containImage, hideImage, className, children, alignTop, alignBottom} = this.props;
    const {isAspectRatio} = this.state;

    const sizeString = this.determineSizeString(isAspectRatio, containImage)
    const alignmentString = alignTop ? 'align-top' : alignBottom ? 'align-bottom' : ''
    return (
      <FullScreenContainer className={className}>
        <div className={`full-screen-image ${sizeString} ${alignmentString}`}>
          <Image className={`full-screen-image__image ${hideImage ? 'hidden' : ''}`} image={image} alt={alt} showAria={this.props.showAria} sizes="100vw" />
          <div className="full-screen-image__overlay">
            {children}
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

FullScreenImage.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  alt: PropTypes.string,
  aspectRatio: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default FullScreenImage;

import React from 'react';
import PropTypes from 'prop-types';
import {TimelineMax, Expo} from 'gsap';
import { AppSettings } from './Layout';

import {TimelineMarkers} from 'utils/scene';

import {
  showElemWithAria, hideElementWithAria
} from 'utils/animation';

const expoEaseOutForward = {ease: Expo.easeOut}
const expoEaseOutBackward = {ease: Expo.easeIn}
const expoEaseInForward = {ease: Expo.easeIn}
const expoEaseInBackward = {ease: Expo.easeIn} // don't change; not sure why but this works better than adding an easeOut here

const timelineComponent = (WrappedComponent, {shouldLog, animation, cache}={}) => {
  return class extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
      super(props);
      this.component = React.createRef();

      const timeline = new TimelineMax({});
      this.timeline =
      timeline.fastHide = (el, offset) => {
        timeline.to(el, 0.1, {...hideElementWithAria}, (offset || "-=0.1"))
      }
      timeline.fastShow = (el, offset) => {
        timeline.to(el, 0.1, {...showElemWithAria}, (offset || "-=0.1"))
      }

      timeline.pause();

      this.timeline = timeline

      this.state = {
      };

      this.handleWindowResize = this.handleWindowResize.bind(this);
      this.setAnimation = this.setAnimation.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowResize);

      if (animation) {
        this.setAnimation();
      }

      this.setTimelinePosition(this.props.position);

    }

    componentWillUnmount() {
      this.timeline.kill();
      window.removeEventListener('resize', this.handleWindowResize);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.position > this.props.position && !this.state.isIncreasing) {
        if (animation) {
          this.setAnimation()
        }

        this.setState({
          isIncreasing: true,
          isDecreasing: false,
        });
      } else if (nextProps.position < this.props.position && !this.state.isDecreasing) {
        if (animation) {
          this.setAnimation(true)
        }

        this.setState({
          isDecreasing: true,
          isIncreasing: false,
        });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.position !== this.props.position) {
        this.setTimelinePosition(this.props.position);
      }
    }

    setTimelinePosition(position) {
      if (this.timeline) {
        this.timeline.seek(position * this.timeline.duration(), false);
      }

      if (shouldLog) {
        console.log('seek to', position);
      }
    }

    setAnimation(goingBackwards) {
      if(!this.timeline.markers) {
        this.timeline.markers = new TimelineMarkers(this.timeline, this.component.current.props.actions);
      } else {
        this.timeline.markers.reset()
      }

      this.timeline.progress(0).kill();
      const targets = this.timeline.getChildren()
      if (!cache) {
        for (var i=0; i<targets.length; i++) {
          TweenMax.set(targets[i].target, {clearProps:"all"});
        };
      }
      this.timeline.clear();

      animation({
        context: this.component.current,
        timeline: this.timeline,
        sceneId: this.props.sceneId,
        setTheme: this.context.setTheme,
        easeOut: goingBackwards ? expoEaseOutBackward : expoEaseOutForward,
        easeIn: goingBackwards ? expoEaseInBackward : expoEaseInForward,
        goingBackwards
      });

      if(!this.timeline.markers.registered) {
        this.timeline.markers.registerStops();
      }
    }

    handleWindowResize() {
      if (animation) {
        this.setAnimation();
      }

      requestAnimationFrame( () => {
        this.setTimelinePosition(this.props.position);
      });
    }

    render() {
      const {isDecreasing, isIncreasing} = this.state;
      return (
        <div>
          <WrappedComponent ref={this.component} timeline={this.timeline} isDecreasing={isDecreasing} isIncreasing={isIncreasing} {...this.props}/>
        </div>
      );
    }
  };
};

export default timelineComponent;

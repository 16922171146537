import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import 'styles/components/credits-modal.scss';
import {Modal} from './Modal';

export const CreditsModal = class extends React.Component {
  render() {
    const {props} = this;

    return (
      <Modal className="credits-modal" {...props}>
        <div className="credits-modal__content">
          <h4 className="uppercase">Credits</h4>
          <p>
            SELMA courtesy of Paramount Pictures and Pathe Productions Limited
            <br/>
            SELMA © 2014 Pathe Productions Limited. All Rights Reserved
          </p>
          <p>
            Selma Online has been made possible through the generous support of
            The Rockefeller Foundation.  We would like to thank Selma director
            Ava DuVernay, Larry McAllister of Paramount Pictures Corporation,
            and Pierre Du Plessis of Pathe Productions Limited, for permission
            to use scenes from the movie.
          </p>
          <br/>
          <h5 className="uppercase">Film Credits</h5>
          <p>
            <span className="bold">Selma</span>
            <br />
              Colson, C., Gardner, D., Kleiner, J., Winfrey, O. (Producers), &
              DuVernay, A. (Executive Producer/Director). (2014). Selma
              [Motion Picture]. United States: Pathé, Harpo Films, Plan B
              Entertainment, Cloud Eight Films, Ingenious Media.
            <br />
            <br />
            <span className="bold">Archive Film Footage</span>
            <br />
            <br />
            <span className="bold">AP Archive</span>
            <br />
            <br />
            <span className="bold">Action FX</span>
            <br />
            <br />
            <span className="bold">Dissolve.com</span>
            <br />
            <br />
            <span className="bold">Oddball Films</span>
            <br />
            <br />
          </p>
          <h5 className="uppercase">Image Credits</h5>
          <p>
            <span className="bold">
              Alabama Department of Archives and History
            </span>
            <br />
            Donated by Alabama Media Group. Photo by Robert Adams or Ed Jones, <i>Birmingham News</i>.
            <br />
            Donated by Alabama Media Group. Photo by Ed Jones, <i>Birmingham News</i>.
            <br />
            <br />
            <span className="bold">Alamy</span>
            <br />
            Archive PL / Alamy Stock Photo
            <br />
            Black Star / Alamy Stock Photo
            <br />
            Everett Collection Inc / Alamy Stock Photo
            <br />
            <br />
            <span className="bold">
              Bob Fitch Photography Archive, Department of Special Collections,
              Stanford University Libraries
            </span>
            <br />
            <br />
            <span className="bold">
              Boston Public Library, Print Department
            </span>
            <br />
            <br />
            <span className="bold">AP Photo</span>
            <br />
            AP Photo/Bill Achatz
            <br />
            AP Photo/Bill Hudson
            <br />
            AP Photo/Dan Grossi
            <br />
            AP Photo/Dave Martin
            <br />
            AP Photo/Henry Borroughs
            <br />
            AP Photo/Horace Cort
            <br />
            AP Photo/Jack Thornell
            <br />
            AP Photo/Jacquelyn Martin
            <br />
            AP Photo/John Raoux
            <br />
            AP Photo/J. Walter Green
            <br />
            AP Photo/Ron Edmonds
            <br />
            AP Photo/The Birmingham News
            <br />
            AP Photo/William J. Smith
            <br />
            AP Video Archive
            <br />
            North Wind Picture Archives via AP Images
            <br />
            <br />
            <span className="bold">CSA Images</span>
            <br />
            csaimages.com
            <br />
            <br />
            <span className="bold">Getty</span>
            <br />
            Afro Newspaper/Gado / Getty Images
            <br />
            Alex Wong / Getty Images
            <br />
            Andrew Lichtenstein / Getty Images
            <br />
            Ben Martin / Getty Images
            <br />
            Bettmann / Getty Images
            <br />
            Bloomberg / Getty Images
            <br />
            Boston Globe / Getty Images
            <br />
            Brooks Kraft / Getty Images
            <br />
            CBS Photo Archive / Getty Images
            <br />
            Charles Moore / Getty Images
            <br />
            Charles Shaw / Getty Images
            <br />
            Charlotte Observer / Getty Images
            <br />
            Chicago History Museum / Getty Images
            <br />
            Christian Science Monitor / Getty Images
            <br />
            David Lienemann / Getty Images
            <br />
            Don Cravens / Getty Images
            <br />
            Don Hogan Charles / Getty Images
            <br />
            Donald Uhrbrock / Getty Images
            <br />
            Flip Schulke Archives / Getty Images
            <br />
            Francis Miller / Getty Images
            <br />
            Frank Dandridge / Getty Images
            <br />
            Gene Forte / Getty Images
            <br />
            Grey Villet / Getty Images
            <br />
            Hearst Newsreel / Getty Images
            <br />
            Joe Raedle / Getty Images
            <br />
            Joseph Scherschel / Getty Images
            <br />
            Kevork Djansezian / Getty Images
            <br />
            Lynsey Addario / Getty Images
            <br />
            Mandel Ngan / Getty Images
            <br />
            Maury Phillips / Getty Images
            <br />
            Michael Ochs Archives / Getty Images
            <br />
            Morton Broffman / Getty Images
            <br />
            MPI / Getty Images
            <br />
            NBC NewsWire / Getty Images
            <br />
            New York Times Co. / Getty Images
            <br />
            Paras Griffin / Getty Images
            <br />
            Photo 12 / Getty Images
            <br />
            PhotoQuest / Getty Images
            <br />
            Pix Inc. / Getty Images
            <br />
            Pool / Getty Images
            <br />
            Ralph Crane / Getty Images
            <br />
            Rolls Press/Popperfoto / Getty Images
            <br />
            Sara D. Davis / Getty Images
            <br />
            Steve Schapiro / Getty Images
            <br />
            Time Life Pictures / Getty IMages
            <br />
            Universal History Archive / Getty Images
            <br />
            UniversalImagesGroup / Getty Images
            <br />
            Washington Bureau / Getty Images
            <br />
            William Lovelace / Getty Images
            <br />
            <br />
            <span className="bold">
              James Reeb photo, courtesy of the Casper Star-Tribune
            </span>
            <br />
            <br />
            <span className="bold">
              Jimmie Lee Jackson photo, courtesy of the Jimmie Lee Jackson family and the Southern Poverty Law Center
            </span>
            <br />
            <br />
            <span className="bold"> johan10 / iStock</span>
            <br />
            <br />
            <span className="bold">Joe Alper Photo Collection</span>
            <br />
            joephotos@comcast.net
            <br />
            <br />
            <span className="bold">
              Lyndon Baines Johnson Presidential Library, Austin, TX
            </span>
            <br />
            LBJ Library photo by Cecil Stoughton
            <br />
            LBJ Library photo by Yoichi Okamoto
            <br />
            <br />
            <span className="bold">Library of Congress</span>
            <br />
            Library of Congress, Geography and Map Division
            <br />
            Library of Congress, Prints & Photographs Division,
            LC-DIG-cwpbh-05089
            <br />
            Library of Congress, Prints & Photographs Division,
            LC-DIG-ppmsca-37242
            <br />
            Library of Congress, Prints & Photographs Division,
            LC-DIG-ppmsca-37947
            <br />
            Library of Congress, Prints & Photographs Division, LC-USZ62-49988
            <br />
            <br />
            <span className="bold">Magnum Photos</span>
            <br />
              Bruce Davidson/Magnum Photos
            <br />
              Danny Lyon/Magnum Photos
            <br />
              Eve Arnold/Magnum Photos
            <br />
            <br />
            <span className="bold">Matt Herron/Take Stock</span>
            <br />
            <br />
            <span className="bold">Newseum</span>
            <br />
            Ted Polumbaum/Newseum collection
            <br />
            <br />
            <span className="bold">
              Pauli Murray photo, courtesy of the Schlesinger Library, Radcliffe Institute, Harvard University
            </span>
            <br />
            <br />
            <span className="bold">
              Richmond Times-Dispatch
            </span>
            <br />
            <br />
            <span className="bold">Spider Martin</span>
            <br />
            SpiderMartin.com
            <br />
            <br />
          </p>
          <h5 className="uppercase">MUSIC LICENSING CREDITS</h5>
          <p>
            "One Morning Soon"
            <br /> Written by Traditional
            <br />
            Performed by Joyce Collins & Johnita Collins
            <br />
            Courtesy of Tompkins Square, LLC
            <br />
            <br />
            "Keep on Pushing"
            <br />
            Written by Curtis Mayfield (as Curtis L. Mayfield)
            <br />
            Performed by The Impressions
            <br />
            Under license from Universal Music Enterprises
            <br />
            <br />
            "Got the New World In My View"
            <br />
            Written and Performed by Sister Gertrude Morgan.
            <br />
            <br />
            "House of the Rising Sun"
            <br />
            Traditional, arranged by Duane Eddy
            <br />
            performed by Duane Eddy
            <br />
            Courtesy of Duane Eddy Productions
            <br />
            <br />
            "Why (Am I Treated So Bad)"
            <br />
            Written by Roebuck 'Pops' Staples (as Roebuck Staples)
            <br />
            Performed by The Staple Singers
            <br />
            Courtesy of Columbia Records, by arrangement with Sony Music
            Licensing
            <br />
            <br />
            "Masters of War"
            <br />
            Written by Bob Dylan
            <br />
            Performed by Odetta
            <br />
            Courtesy of RCA Records, by arrangement with Sony Music Licensing
            <br />
            <br />
            "Yesterday Was Hard On All of Us"
            <br />
            Written by Fink (as Finian Greenall), Guy Whittaker & Tim Thornton
            <br />
            Performed by Fink
            <br />
            Courtesy of Ninja Tune.
            <br />
            <br />
          </p>
          <h5 className="uppercase">Left Field Labs</h5>
          <p>
            <span className="bold">Leadership</span>
            <br />
            Sarah Mehler - CEO
            <br />
            Eric Lee - CTO
            <br />
            <br />
            <span className="bold">Production</span>
            <br/>
            Michelle Mundy - Executive Director, Production
            <br/>
            Jackie Jacobson - Executive Producer
            <br/>
            Kat Olschnegger - Senior Producer
            <br/>
            Maya Harris -  Production Coordinator
            <br />
            <br />
            <span className="bold">Strategy & User Experience</span>
            <br />
            Meri Gulyan - Executive Director, Strategy & User Experience
            <br />
            Kristyn Dunlap - Senior Strategist
            <br />
            Audrey Beachle - Sr UX Designer
            <br />
            <br />
            <span className="bold">Technology</span>
            <br />
            Andy MacDonald - Executive Director, Technology
            <br />
            Conor Britain - Developer
            <br />
            Caliandra Harmon - Developer
            <br />
            Altheria Weaver - QA Analyst
            <br />
            Felipe Vega - Developer
            <br />
            Harini Puthiyadeth  - QA Analyst
            <br />
            <br />
            <span className="bold">Creative</span>
            <br />
            Tobias Mehler - Executive Creative Director
            <br />
            Yann Caloghiris - Executive Creative Director
            <br />
            Jason Radovan - Art Director
            <br />
            Ji Lee - Designer
            <br />
            Galo Carrillo - Designer
            <br />
            Jake Kahana - Designer
            <br />
            <br />
          </p>
          <h5 className="uppercase">ACKNOWLEDGMENTS</h5>
          <p>
            <span className="bold">The Rockefeller Foundation</span>
            <br />
            <br />
            <span className="bold">Ava DuVernay</span>
            <br />
            <br />
            <span className="bold">
              Hutchins Center for African and African American Studies, Harvard
              University
            </span>
            <br/>
            Henry Louis Gates, Jr.
            <br/>
            Kevin Burke
            <br/>
            Sheldon Cheek
            <br/>
            Shawn S. Lee
            <br/>
            Steven J. Niven
            <br/>
            Tom Wolejko
            <br/>
            Abby Wolf
            <br />
            <br />
            <span className="bold">Curriculum Partner: Teaching Tolerance</span>
            <br />
            Maureen Costello
            <br />
            Willemijn Keizer
            <br />
            Sara Wicht
            <br />
            <br />
            <span className="bold">Design Partner: Left Field Labs</span>
            <br />
            <br />
            <span className="bold">
              Paramount Pictures Corporation
            </span>
            <br />
            Larry McAllister
            <br />
            <br />
            <span className="bold">
              Pathe Productions Limited
            </span>
            <br />
              Pierre Du Plessis
            <br />
            <br />
            <span className="bold">
              Publicity Partners
            </span>
            <br />
            Glover Park Group
            <br />
            ID:PR
            <br />
            <br />
            <span className="bold">
              Music Rights and Permission Consultants: Universal Media, Inc.
            </span>
            <br />
            Eric L. Kulberg
            <br />
            John Paige
            <br />
            <br />
            <span className="bold">
              Music Clearances Consultants: Clear Cut Inc.
            </span>
            <br />
            Barb Hall
            <br />
            John Fry
            <br />
            <br />
            <span className="bold">
              Selma Online Academic Advisory Board
            </span>
            <br/>
            Carol Anderson, Emory University
            <br/>
            Tomiko Brown-Nagin, Dean, Radcliffe Institute, Harvard University
            <br/>
            Bettina L. Love, University of Georgia
            <br/>
            Pippa Norris, Harvard University
            <br/>
            Jason Sokol, University of New Hampshire
            <br />
            <br />
            <span className="bold">
              Selma Online Teacher Advisory Board
            </span>
            <br/>
            Ryan Burrows, Satanta High School, Satanta, KS
            <br/>
            Lori Bigler, Meade High School, Meade, KS
            <br/>
            Kim Estelle, Academy for Academics and Arts, Huntsville, AL
            <br/>
            Chris Knight, Albany High School, Albany, CA
            <br/>
            Rachel Nicholas, Taft High School, Chicago, IL
            <br/>
            Lori Thurman, Owensboro High School, Owensboro, KY
          </p>
        </div>
      </Modal>
    );
  }
};

export default CreditsModal;

import React from 'react';

export class TimelineMarkers {
  constructor(timelineMaxInstance, sceneActions) {
    this.timeline = timelineMaxInstance
    this.sceneActions = sceneActions
    this.markers = []
    this.currentMarkerId = 0
  }

  reset() {
    this.markers = []
    this.currentMarkerId = 0
  }

  createTimelineMarker({val, id}={}) {
    const value = val || this.timeline.duration()
    this.markers.push({id: id || this.currentMarkerId, value, shouldConvert: !val})
    this.currentMarkerId++
  }

  convertMarkersToStops() {
    const finalDuration = this.timeline.duration()
    return this.markers.map(({id, value, shouldConvert}) => {
      return {
        id,
        value: shouldConvert ? value / finalDuration : value
      }
    })
  }

  registerStops() {
    this.registered = true
    const stops = this.convertMarkersToStops()

    if (this.sceneActions) {
      this.sceneActions.registerSceneStops(stops)
      this.sceneActions.updateDuration(this.timeline.duration())
    }
  }
}

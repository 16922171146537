import React from 'react';
import {Link} from 'gatsby';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import {FacebookShare, TwitterShare} from 'components/social';
import '../styles/components/navbar.scss';
import ScrollContainer from 'components/ScrollContainer';
import {useStaticQuery, graphql} from 'gatsby';
import {Modal} from './Modal';
import FtueModal from 'components/FtueModal';
import CreditsModal from 'components/CreditsModal';
import bgImg from '../img/landing/landing-background-bw.jpg';

import SelmaOnlineGuide from '../docs/Selma_Online_Teaching_Guide.pdf';

import LflLogo from '../img/landing/lfl-logo.svg';

const contactContent = 'selmaonline-feedback@fas.harvard.edu?subject=Selma%20Online%20Platform%20Feedback&body=Please%20share%20with%20us%20any%20questions%20or%20feedback%20you%20have%20about%20the%20platform.%20Let%20us%20know%20how%20your%20students%20responded%20to%20the%20%22Do%20Something%22%20segment.%0D%0A%0D%0A';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.boundToggleNav = this.toggleNav.bind(this);
  }
  componentDidMount() {
    this._lastTheme = this.props.theme;
  }

  onHamburgerKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      // enter or space buttons
      this.toggleNav();
    }
  };

  toggleNav(e) {
    const mainPage = document.getElementById('mainPage');
    this.setState({isActive: !this.state.isActive});
    if (!this.state.isActive) {
      this._lastTheme = this.props.theme;
      this.props.setTheme('light');
      mainPage.setAttribute('aria-hidden', 'true');
    } else {
      this.props.setTheme(this._lastTheme);
      mainPage.setAttribute('aria-hidden', 'false');
    }
  }

  render() {
    const {isActive} = this.state;
    const tabIndexDefault = 0;
    const tabIndexValue = isActive ? tabIndexDefault : -1;

    const {logo1, logo2, logo3, logo4} = this.props.data;

    return (
      <nav
        className={`navbar ${this.props.theme}`}
        // role="navigation"
        aria-label="main-navigation"
      >
        <button
          className={`hamburger ${isActive ? 'is-active' : ''} ${
            this.props.theme
          }`}
          id="mainNavigation"
          onClick={this.boundToggleNav}
          aria-label="Hamburger Box"
          tabIndex={tabIndexDefault}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner" />
          </div>
          <div className="chapter-title">{this.props.title}</div>
        </button>
        <div
          className={`container ${isActive ? 'is-active' : ''}`}
          id="navMenu"
          aria-hidden={!isActive}
        >
          <div className="dropdown">
            <ScrollContainer className="dropdown-scroll-container">
              <div className="navbar-container">

                <div className="nav-upper-right">
                  <button
                    className="contact-us medium naked"
                    tabIndex={tabIndexValue}
                    onClick={() => {
                      trackCustomEvent({
                        category: 'buttons',
                        action: 'Click',
                        label: 'Contact Us button click',
                      });
                    }}>
                    <a href={`mailto:${contactContent}`}>
                        Contact Us
                    </a>
                  </button>
                  <FtueModal
                    buttonEl={
                      <svg
                        className="help-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <g fill="none" fillRule="evenodd">
                          <circle cx="18" cy="18" r="12" strokeWidth="1.6" />
                          <path
                            fillRule="nonzero"
                            d="M17.012 21.182a.285.285 0 0 1-.215-.081.309.309 0 0 1-.078-.224v-.915c0-.61.15-1.11.45-1.503.3-.393.736-.813 1.31-1.26.535-.42.94-.8 1.213-1.139.274-.338.41-.772.41-1.3 0-.61-.192-1.094-.576-1.453-.385-.36-.89-.539-1.516-.539-.64 0-1.154.186-1.545.559-.392.373-.587.864-.587 1.473v.427c0 .19-.098.285-.294.285l-1.29-.04a.268.268 0 0 1-.216-.092.347.347 0 0 1-.078-.234V14.8c0-.745.17-1.405.509-1.981a3.467 3.467 0 0 1 1.437-1.341c.62-.319 1.327-.478 2.122-.478 1.187 0 2.139.339 2.856 1.016.717.678 1.076 1.572 1.076 2.683 0 .596-.098 1.11-.293 1.544-.196.434-.43.79-.705 1.067a9.75 9.75 0 0 1-1.056.905c-.456.352-.792.657-1.007.914a1.41 1.41 0 0 0-.323.935v.813c0 .095-.026.17-.078.224a.285.285 0 0 1-.215.08h-1.31zM17.683 25c-.335 0-.623-.124-.862-.372a1.249 1.249 0 0 1-.36-.9c0-.353.12-.653.36-.901s.527-.372.862-.372c.324 0 .611.124.863.372.251.248.377.548.377.9 0 .353-.123.653-.368.901a1.185 1.185 0 0 1-.872.372z"
                          />
                        </g>
                      </svg>
                    }
                  />
                </div>
                <div className="navbar-menu">
                  {navItems.map((nav, index) => {
                    const transitionDelay = isActive ? index * 0.07 + 0.25 : 0.25;
                    const style = {
                      transitionDelay: `${transitionDelay}s`,
                    };
                    return (
                      <Link
                        key={index}
                        className="navbar-item"
                        style={style}
                        to={nav.url}
                        activeClassName="active"
                        onClick={() => {
                          trackCustomEvent({
                            category: 'buttons',
                            action: 'Click',
                            label: `${nav.title} nav item click`,
                          });
                          this.boundToggleNav();
                        }}
                        aria-label={`${nav.label ? nav.label + ':' : ''} ${
                          nav.title
                        }`}
                        tabIndex={tabIndexValue}
                      >

                        <div className="active-bar" />
                        <div className="navbar-item__text">
                          <div className="link-label">{nav.label}</div>
                          <div className="link-title h4">{nav.title}</div>
                        </div>
                      </Link>
                    );
                  })}
                  <div className="navbar-item download-link"
                    style={{transitionDelay: `${isActive ? (navItems.length * 0.07) + 0.25 : 0.25}s`}}
                  >
                    <div className="navbar-item__text h4">Selma Online Teaching Guide</div>

                    <a
                      onClick={() => {
                        trackCustomEvent({
                          category: 'buttons',
                          action: 'Click',
                          label: 'Teachers Guide download button click',
                        });
                      }}
                      href={SelmaOnlineGuide}
                      target="_blank"
                      tabIndex={tabIndexValue}
                    >
                      <button
                        aria-label="Download Online Teaching Guide"
                        className="link-button"
                        onClick={() => {
                          trackCustomEvent({
                            category: 'buttons',
                            action: 'Click',
                            label: 'Download Online Teaching Guide',
                          });
                        }}>
                          Download - PDF 3mb
                      </button>
                    </a>
                  </div>
                </div>

                <div className="nav-footer">
                  <div className="nav-footer__left">
                    <Link
                      to={'/'}
                      onClick={this.boundToggleNav}
                      aria-label={`home-button`}
                      tabIndex={0}
                    >
                      <h1 className="display red">Selma</h1>
                    </Link>
                    <div className="logos">
                      <a href="https://hutchinscenter.fas.harvard.edu" target="_blank" tabIndex={tabIndexValue}>
                        <img
                          src={logo1.childImageSharp.fluid.src}
                          alt="Hutchins Center for African & African American Research"
                        />
                      </a>
                      {/* <a href="https://www.splcenter.org/" target="_blank" tabIndex={tabIndexValue}>
                        <img src={logo2.childImageSharp.fluid.src} alt="Southern Poverty Law Center" />
                      </a>*/}
                      <a href="https://www.tolerance.org/" target="_blank" tabIndex={tabIndexValue}>
                        <img src={logo3.childImageSharp.fluid.src} alt="Teaching Tolerance"/>
                      </a>
                      <a href="https://www.rockefellerfoundation.org/" target="_blank" tabIndex={tabIndexValue}>
                        <img src={logo4.childImageSharp.fluid.src} alt="The Rockefeller Foundation"/>
                      </a>
                      <a
                        href="http://www.leftfieldlabs.com"
                        target="_blank"
                        aria-label="Left Field Labs"
                        tabIndex={tabIndexValue}
                      >
                        <LflLogo />
                      </a>
                    </div>
                    <div className="social">
                      <FacebookShare tabIndexValue={tabIndexValue} />
                      <TwitterShare tabIndexValue={tabIndexValue} />
                    </div>
                    <div className="credits">
                      <CreditsModal
                        buttonEl={
                          <div
                            className="credits__button"
                            onClick={() => {
                              trackCustomEvent({
                                category: 'buttons',
                                action: 'Click',
                                label: 'Credits button click',
                              });
                            }}>
                              Credits
                          </div>
                        }
                      />
                    </div>
                  </div>

                </div>
              </div>
            </ScrollContainer>
          </div>
        </div>
      </nav>
    );
  }
};

const navItems = [
  {
    url: '/introduction',
    label: '',
    title: 'Introduction',
  },
  {
    url: '/chapter-1',
    label: 'Chapter 1',
    title: 'Race & Voting Rights',
  },
  {
    url: '/chapter-2',
    label: 'Chapter 2',
    title: 'Democracy Denied in Dallas County',
  },
  {
    url: '/chapter-3',
    label: 'Chapter 3',
    title: 'Youth Activism & Voting Rights',
  },
  {
    url: '/chapter-4',
    label: 'Chapter 4',
    title: 'Who’s Who in Selma',
  },
  {
    url: '/chapter-5',
    label: 'Chapter 5',
    title: 'Selma’s Pivotal Moments',
  },
  {
    url: '/chapter-6',
    label: 'Chapter 6',
    title: 'Presidential Support',
  },
  {
    url: '/chapter-7',
    label: 'Chapter 7',
    title: 'The Right to Vote',
  },
  {
    url: '/conclusion',
    label: '',
    title: 'Conclusion',
  },
];


export const query = graphql`
  query {
      logo1: file(relativePath: { eq: "landing/hutchins.png" }) {
        ...smallImage
      }
      logo2: file(relativePath: { eq: "landing/splc.png" }) {
        ...smallImage
      }
      logo3: file(relativePath: { eq: "landing/teaching-tolerance.png" }) {
        ...smallImage
      }
      logo4: file(relativePath: { eq: "landing/rockerfeller.png" }) {
        ...smallImage
      }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <Navbar data={data} {...props} />;
};

import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/modal.scss';
import {ModalContent, stickyPositions} from './ModalContent';
import {TweenMax} from 'gsap';

export const Modal = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalActive: false,
    };
  }

  componentWillUnmount() {
    TweenMax.killTweensOf(this.triggerButton);
  }

  componentDidUpdate() {
    const overflow = this.state.isModalActive ? 'hidden' : 'initial';
    document
      .querySelector('body')
      .setAttribute('style', `overflow: ${overflow}`);
  }

  onOpenClick = () => {
    let shouldContinue = true;
    if (this.props.onOpenClick) {
      shouldContinue = this.props.onOpenClick();
    }
    if (!shouldContinue) return;

    const {cancelButtonAnimation} = this.props;
    TweenMax.to(this.triggerButton, cancelButtonAnimation ? 0.1 : 0.5, {
      opacity: cancelButtonAnimation ? 1 : 0,
      onComplete: () => this.setState({isModalActive: true}),
    });
  };

  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }

    TweenMax.to(this.triggerButton, 0.5, {
      opacity: 1,
      onComplete: () => this.setState({isModalActive: false}),
    });
  };

  render() {
    const {className, stickyPosition, buttonEl, enabledVal} = this.props;
    const stickyPositionVal = !stickyPosition
      ? stickyPositions.NO_STICKY
      : stickyPosition;
    return (
      <div
        className={`selma-modal-trigger ${className} ${stickyPositionVal} ${
          enabledVal ? enabledVal : ''
        }`}
      >
        {buttonEl && (
          <div
            className="selma-modal-trigger__custom-btn"
            onClick={this.onOpenClick}
            onKeyPress={this.onOpenClick}
            role="button"
            tabIndex={0}
            ref={(ref) => (this.triggerButton = ref)}
          >
            {buttonEl}
          </div>
        )}

        {!buttonEl && (
          <button
            className="selma-modal-trigger__btn"
            onClick={this.onOpenClick}
            ref={(ref) => (this.triggerButton = ref)}
            name="open modal"
            type="button"
            aria-label="Open Modal"
          >
            <div className="trigger-background">
              <div className="trigger-cross" />
            </div>
          </button>
        )}

        {this.state.isModalActive && (
          <ModalContent ref={(ref)=> this.modalContent = ref}{...this.props} onClose={this.onCloseClick} />
        )}
      </div>
    );
  }
};

Modal.propTypes = {
  className: PropTypes.string,
  stickyPosition: ModalContent.propTypes.stickyPosition,
  cancelButtonAnimation: PropTypes.bool,
  buttonEl: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export default Modal;

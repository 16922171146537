
import React from 'react';
import {AppSettings} from './Layout';

import 'styles/components/full-screen-container.scss';

const FullScreenContainer = class extends React.Component {
  static contextType = AppSettings
  componentDidMount() {
    if (this.props.inheritHeight) {
      this.container.style.height = this.container.parentElement.offsetHeight + 'px';
    }
  }
  render() {
    const {inheritHeight} = this.props;

    let height;
    if (inheritHeight) {
      if (this.container) {
        height = this.container.parentElement.offsetHeight + 'px';
      }
    } else {
      height = this.context.screenHeight;
    }

    return (
      <div ref={(ref) => this.container = ref} style={{width: this.context.screenWidth, height}} className={`full-screen-container ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
};

export default FullScreenContainer;

import React from 'react';
import 'styles/pages/chapter-page.scss';
import Helmet from 'react-helmet';
import Layout from 'components/Layout';
import {throttled, queryParamsToObject} from '../utils/index';
// import disableScroll from 'disable-scroll';
import ScrollManager from '../utils/ScrollManager';
import FullScreenContainer from 'components/FullScreenContainer';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {AppSettings} from './Layout';

export const ChapterPageContext = React.createContext(0);

export const contentDirections = {
  FORWARDS: 'contentDirections/FORWARDS',
  BACKWARDS: 'contentDirections/BACKWARDS',
};

const defaultTransitionTime = 1000;

const Chapter = class extends React.Component {
  static contextType = AppSettings

  constructor(props) {
    super(props);
    let queryParams = {};
    if (typeof window !== `undefined`) {
      queryParams = this.getQueryParams();
    }
    const activeScene = queryParams.scene && queryParams.scene <= props.scenes.length ? Number(queryParams.scene - 1) : 0;
    const startingSection = Number(queryParams.section || 0);

    this.state = {
      activeScene,
      startingSection,
      uiEnabled: true,
      prevDisabled: activeScene === 0 && startingSection === 0,
      updates: 0,
    };

    const throttledKeyPress = throttled(1000, this.handleKeyPress.bind(this));
    const throttledScrollWheel = throttled(
      1000,
      this.handleScrollWheel.bind(this)
    );
    this.handleTouchMove = throttled(1000, this.handleTouchMove.bind(this));

    this.scrollManager = new ScrollManager({
      // preventDefaultEvents: true,
      onScrollWheel: throttledScrollWheel,
      onKeyPress: throttledKeyPress,
      onTouchMove: this.handleTouchMove,
    });

    this.setPrevButton = this.setPrevButton.bind(this);
    this.setNextButton = this.setNextButton.bind(this);
    this.changeScene = this.changeScene.bind(this);
    this.disableUi = this.disableUi.bind(this);
    this.enableUi = this.enableUi.bind(this);
  }

  componentDidMount() {
    this.scrollManager.enable();
  }

  componentWillUnmount() {
    this.scrollManager.disable();

    this.killEnableUiTimeout();
    this.killEnableSceneTimeout();
    this.killDisableActiveButtonTimeout();
  }

  getQueryParams() {
    const queryParams = queryParamsToObject();
    const {scene, section} = queryParams || {};
    return {scene, section};
  }

  get shouldPreventInteraction() {
    return !this.state.uiEnabled || this.context.userMustRotate;
  }

  handlePrevClick() {
    if (this.shouldPreventInteraction) return;
    this.moveContentBackwards();
  }

  handleNextClick() {
    if (this.shouldPreventInteraction) return;
    this.moveContentForwards();
  }

  handleKeyPress(e) {
    if (this.shouldPreventInteraction) return;
    const {which} = e;
    if (which === 38) {
      this.moveContentBackwards();
      e.preventDefault();
    } else if (which === 40) {
      this.moveContentForwards();
      e.preventDefault();
    }
  }

  handleScrollWheel(e) {
    if (this.shouldPreventInteraction) return;
    const {deltaY} = e;
    if (deltaY > 0) {
      this.moveContentForwards();
    } else if (deltaY < 0) {
      this.moveContentBackwards();
    }
  }

  handleTouchMove(e) {
    if (this.shouldPreventInteraction) return;
    const startY = e.touches[0].clientY;

    const handler = (newE) => {
      const currentY = newE.touches[0].clientY;
      if (currentY > startY) {
        this.moveContentBackwards();
      } else if (currentY < startY) {
        this.moveContentForwards();
      }
      this.scrollManager.setOnTouchMove(this.handleTouchMove);
    };

    this.scrollManager.setOnTouchMove(throttled(1000, handler));
  }

  moveContentBackwards() {
    if (this.state.prevDisabled || this.context.displayChangeMessage) return;
    this.updateContent(contentDirections.BACKWARDS);
    this.setState({
      activeButton: 'prev',
      nextDisabled: false,
    });

    this.disableActiveButtonTimeout = setTimeout(() => {
      this.setState({
        activeButton: null,
      });
    }, 500);
  }


  moveContentForwards() {
    if (this.state.nextDisabled || this.context.displayChangeMessage) return;
    this.updateContent(contentDirections.FORWARDS);
    this.setState({
      activeButton: 'next',
      prevDisabled: false,
    });

    this.disableActiveButtonTimeout = setTimeout(() => {
      this.setState({
        activeButton: null,
      });
    }, 500);
  }

  updateContent(contentDirection) {
    const {startingSection} = this.state;

    const newState = Object.assign({}, this.state, {
      updates: this.state.updates + 1,
      contentDirection,
    });

    if (startingSection !== undefined && startingSection !== null) {
      newState.startingSection = null;
    }

    this.setState(newState);
  }

  disableUi() {
    this.killEnableUiTimeout();
    this.setState({
      uiEnabled: false,
    });
  }

  enableUi() {
    this.setState({
      uiEnabled: true,
      activeButton: null,
    });
  }

  killEnableUiTimeout() {
    if (this.enableUiTimeout) {
      clearTimeout(this.enableUiTimeout);
      this.enableUiTimeout = null;
    }
  }

  killEnableSceneTimeout() {
    if (this.enableSceneTimeout) {
      clearTimeout(this.enableSceneTimeout);
      this.enableSceneTimeout = null;
    }
  }

  killDisableActiveButtonTimeout() {
    if (this.disableActiveButtonTimeout) {
      clearTimeout(this.disableActiveButtonTimeout);
      this.disableActiveButtonTimeout = null;
    }
  }

  changeScene(contentDirection) {
    const {activeScene, startingSection} = this.state;
    const {scenes} = this.props;
    const goBackwards = contentDirection === contentDirections.BACKWARDS;
    if (activeScene === 0 && goBackwards) {
      return;
    } else {
      const goForwards = contentDirection === contentDirections.FORWARDS;
      if (goForwards && activeScene === scenes.length - 1) {
        return;
      } else {
        this.setState({
          activeScene: goForwards ? activeScene + 1 : activeScene - 1,
          changingScene: true,
          uiEnabled: false,
          activeButton: goBackwards ? 'prev' : 'next',
          contentDirection: contentDirection,
        });

        this.changeSceneTimeout = setTimeout(() => {
          this.setState({
            changingScene: false,
          });
        }, defaultTransitionTime);

        this.enableUiTimeout = setTimeout( this.enableUi, defaultTransitionTime);
      }
    }
  }

  setPrevButton() {
    const {activeScene} = this.state;
    const {scenes} = this.props;

    if (activeScene === 0) {
      this.setState({
        prevDisabled: true,
      });
    } else if (this.state.prevDisabled) {
      this.setState({
        prevDisabled: false,
      });
    }
  }

  setNextButton() {
    const {activeScene} = this.state;
    const {scenes} = this.props;
    if (activeScene === scenes.length - 1) {
      this.setState({
        nextDisabled: true,
      });
    } else if (this.state.nextDisabled) {
      this.setState({
        nextDisabled: false,
      });
    }
  }

  render() {
    const {
      props,
      state} = this;

    const {
      presentationMode,
      scenes} = props;

    const {
      updates,
      startingSection} = this.state;

    const {
      activeScene,
      contentDirection,
      changingScene,
      uiEnabled} = state;

    const scene = scenes[activeScene];

    const actions = {
      changeScene: this.changeScene,
      setPrevButton: this.setPrevButton,
      setNextButton: this.setNextButton,
      disableUi: this.disableUi,
      enableUi: this.enableUi,
    };

    return (
      <FullScreenContainer>

        <ChapterPageContext.Provider value={{
          actions,
    			contentDirection,
          startingSection,
          uiEnabled,
    			updates,
        }}>
    			<TransitionGroup>
    				<CSSTransition
              key={activeScene}
              timeout={defaultTransitionTime}
              classNames="chapter__scene-transition"
            >
    					{scene}
    				</CSSTransition>
    			</TransitionGroup>

          {/* DO NOT DELETE: needed for getting around Gatsby's css purge task */}
          <div className="chapter__scene-transition-enter chapter__scene-transition-enter-active chapter__scene-transition-exit chapter__scene-transition-exit-active" />
          {/* end of DO NOT DELETE */}

        </ChapterPageContext.Provider>

        {this.renderUi()}

      </FullScreenContainer>
    );
  }

  renderUi() {
    const {
      uiEnabled,
      activeButton,
      prevDisabled,
      nextDisabled,
    } = this.state;

    const uiState = uiEnabled ? 'enabled' : 'disabled';
    const prevButtonActive = activeButton === 'prev';
    const nextButtonActive = activeButton === 'next';
    const prevButtonClass = prevButtonActive
      ? 'active'
      : prevDisabled
        ? 'disabled'
        : uiState;
    const nextButtonClass = nextButtonActive
      ? 'active'
      : nextDisabled
        ? 'disabled'
        : uiState;

    return (
      <div className={`chapter__ui ${uiState}`}>
        <div className="chapter__ui__buttons" role="navigation">
          <button
            aria-label="Next"
            tabIndex={nextDisabled ? -1 : 0}
            className={`chapter__ui__buttons__button ${nextButtonClass} next`}
            onClick={() => this.handleNextClick()}>
            {chevronSvg()}
          </button>
          <button
            aria-label="Previous"
            tabIndex={prevDisabled ? -1 : 0}
            className={`chapter__ui__buttons__button ${prevButtonClass} prev`}
            onClick={() => this.handlePrevClick()}>
            {chevronSvg()}
          </button>
        </div>
      </div>
    );
  }
};

const chevronSvg = () => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    fill="none"
    stroke="#FFFFFF"
    strokeWidth="2">
    <polyline points="5 12 18 24 31 12" />
  </svg>
);

const ChapterPage = class extends React.Component {
  render() {
    const {title, description, scenes} = this.props;

    return (
      <Layout>
        <Helmet titleTemplate="%s | Chapter">
          <title>{title}</title>
          <meta name="description" content={description || 'Chapter Description'} />
        </Helmet>

        <div className="chapter">
          <Chapter scenes={scenes} />
        </div>
      </Layout>
    );
  }
};

export default ChapterPage;

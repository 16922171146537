const keys = {37: 1, 38: 1, 39: 1, 40: 1};

const preventDefault = (e) => {
  e = e || window.event;
  if (e.preventDefault) {
    e.preventDefault();
  }
  e.returnValue = false;
};

const preventDefaultForScrollKeys = (e) => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};

class ScrollManager {
  constructor(props) {
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleScrollWheel = this.handleScrollWheel.bind(this);

    this.setOnKeyPress(props.onKeyPress);
    this.setOnTouchStart(props.onTouchStart);
    this.setOnTouchMove(props.onTouchMove);
    this.setOnTouchEnd(props.onTouchEnd);
    this.setOnScrollWheel(props.onScrollWheel);

    const defaultTarget = (typeof window !== `undefined`) ? document : null;
    this.target = props.target || defaultTarget;
    this.preventDefaultEvents = props.preventDefaultEvents;
  }

  // TODO: make these actual setters
  setOnTouchStart(handler) {
    this.onTouchStart = handler;
  }

  setOnTouchMove(handler) {
    this.onTouchMove = handler;
  }

  setOnTouchEnd(handler) {
    this.onTouchEnd = handler;
  }

  setOnKeyPress(handler) {
    this.onKeyPress = handler;
  }

  setOnScrollWheel(handler) {
    this.onScrollWheel = handler;
  }

  enable() {
    if (window.addEventListener) {
      window.addEventListener('DOMMouseScroll', this.handleScrollWheel, false);
    }
    this.target.addEventListener('wheel', this.handleScrollWheel, {passive: false});
    this.target.addEventListener('touchstart', this.handleTouchStart, {passive: false});
    this.target.addEventListener('touchmove', this.handleTouchMove, {passive: false});
    this.target.addEventListener('touchend', this.handleTouchEnd, {passive: false});
    this.target.addEventListener('keydown', this.handleKeyPress, {passive: false});
  }

  disable() {
    if (window.removeEventListener) {
      window.removeEventListener('DOMMouseScroll', this.handleScrollWheel, false);
    }
    this.target.removeEventListener('wheel', this.handleScrollWheel, {passive: false});
    this.target.removeEventListener('touchstart', this.handleTouchStart, {passive: false});
    this.target.removeEventListener('touchmove', this.handleTouchMove, {passive: false});
    this.target.removeEventListener('touchend', this.handleTouchEnd, {passive: false});
    this.target.removeEventListener('keydown', this.handleKeyPress, {passive: false});
  };

  handleTouchStart(e) {
    if (this.onTouchStart) {
      this.onTouchStart(e);
    }
  }


  handleTouchEnd(e) {
    if (this.onTouchEnd) {
      this.onTouchEnd(e);
    }
  }

  handleTouchMove(e) {
    if (this.onTouchMove) {
      this.onTouchMove(e);
    }
    if (this.preventDefaultEvents) {
      preventDefault(e);
    }
  }

  handleKeyPress(e) {
    if (this.onKeyPress) {
      this.onKeyPress(e);
    }
    if (this.preventDefaultEvents) {
      preventDefaultForScrollKeys(e);
    }
  }

  handleScrollWheel(e) {
    if (this.onScrollWheel) {
      this.onScrollWheel(e);
    }
    if (this.preventDefaultEvents) {
      preventDefault(e);
    }
  }
}

export default ScrollManager;

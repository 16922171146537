import React from 'react';
import ReactDOM from 'react-dom';
import {TweenMax} from 'gsap';
import {Link} from 'gatsby';
import ScrollContainer from 'components/ScrollContainer';
import {tabbable} from 'utils/accessibility';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';

import {
  showElemWithAria, hideElementWithAria, setThenRemoveFocus,
} from 'utils/animation';

import 'styles/components/qa-content.scss';
import {AppSettings} from './Layout';
const QaQuestion = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnswerOpened: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    TweenMax.killTweensOf(this.answerButton);
    TweenMax.killTweensOf(this.paragraph);
  }

  openAnswer = () => {
    console.log('bye');
    TweenMax.to(this.answerButton, 0.3, {
      ...hideElementWithAria,
      onComplete: this.answerTween,
    });
  };

  answerTween = () => {
    console.log('hi');
    this.setState({isAnswerOpened: true});
    TweenMax.set(this.paragraph, {height: 'auto', ...showElemWithAria});
    TweenMax.from(this.paragraph, 0.6, {height: 0, ...hideElementWithAria, onComplete: () => {
      this.paragraph.focus();
    }});
  };

  render() {
    const {questionNumber, question, answer} = this.props;
    const {isAnswerOpened} = this.state;
    return (
      <div className="qa-question">
        <div {...tabbable} className="question-number subhead">{questionNumber}</div>
        <div {...tabbable} className="subhead">{question}</div>
        {!isAnswerOpened && (
          <button
            className="secondary"
            ref={(ref) => (this.answerButton = ref)}
            onClick={this.openAnswer}>
            ANSWER
          </button>
        )}
        {isAnswerOpened && (
          <p {...tabbable} className="alt" ref={(ref) => (this.paragraph = ref)}>
            {answer}
          </p>
        )}
      </div>
    );
  }
};

const QaContent = class extends React.Component {
  static contextType = AppSettings;
  constructor(props) {
    super(props);
    this.qaContainer = document.createElement('div');
    document.body.appendChild(this.qaContainer);
    this.boundHandleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    TweenMax.set(this.qaContentRef, {...showElemWithAria});
    TweenMax.from(this.qaContentRef, 0.6, {...hideElementWithAria});
    this.context.setTheme('dark');
  }

  componentWillUnmount() {
    document.body.removeChild(this.qaContainer);
    TweenMax.killTweensOf(this.qaContentRef);
  }
  handleClose() {
    TweenMax.to(this.qaContentRef, 0.5, {
      ...hideElementWithAria,
      onComplete: () => {
        if (this.props.onCloseQA) {
          this.props.onCloseQA();
        }
      },
    });
  }
  render() {
    const {dataContent, continueLabel, continueCta} = this.props;

    return ReactDOM.createPortal(
      <div className="qa-content" ref={(ref) => (this.qaContentRef = ref)}>
        <QaFocus />
        <ScrollContainer className="qa-content__container">
          <div className="qa-content__questions">
            {dataContent.map((value, key) => (
              <QaQuestion {...value} key={`qa-content-${key}`} />
            ))}
          </div>
        </ScrollContainer>
        <div className="qa-content__top-head" />
        <div className="qa-content__gradient" />
        {continueCta && (
          <Link to={continueCta} className="qa-content__continue">
            <button onClick={() => {
              trackCustomEvent({
                category: 'buttons',
                action: 'Click',
                label: `${this.props.label} continue button click`,
              });
            }} className="continue-btn">{continueLabel}</button>
          </Link>
        )}
        <button className="close-btn"
          name="Close Qa Content"
          type="button"
          aria-label="Close Qa Content"
          onClick={this.boundHandleClose}>
          <div className="close-btn__icon"/>
        </button>
      </div>,
      this.qaContainer
    );
  }
};

class QaFocus extends React.Component {
  componentDidMount() {
    setThenRemoveFocus(this.qaFocus);
  }
  render() {
    return <div ref={(ref) => this.qaFocus = ref} />;
  }
}

export default QaContent;
